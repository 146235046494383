import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { VehicleIdentification } from '../walk-in/models/vehicle-identification.model';
import { catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { BankDetails } from '../bank-verification/models/bank-details.model';
import { AddressSummary } from '../models/address-summary';
import { KeyValuePair } from '../models/key-value-pair.model';
import { Vat } from '../models/vat.model';
import { NotPurchasedReason } from '../not-purchased-reasons/models/not-purchased-reason.model';
import { VehicleDetails } from '../models/vehicle-details';

@Injectable({
  providedIn: 'root',
})

export class LookupService {
   constructor(private http: HttpClient) { }

  findVehicle$(vrm: string): Observable<VehicleIdentification> {
    return this.http.get<VehicleIdentification>(`${environment.ppxPrivateApi}api/ToDo/vehicleLookup/${vrm}`).pipe(
      catchError(err => throwError(err))
    );
  }

  vehicleCheckRelatedVrms$(appointmentVrm: string, vrmEntered: string, quoteStateId : number, isAltVrm: boolean): Observable<boolean> {
    return this.http.get<boolean>(`${environment.ppxPrivateApi}api/ToDo/vehicleCheckRelatedVrms/${appointmentVrm}/${vrmEntered}/${quoteStateId}/${isAltVrm}`).pipe(
      catchError(err => throwError(err))
    );
  }

  updateVrm$(quoteStateId: number, authType: number): Observable<VehicleDetails> {
    return this.http.get<VehicleDetails>(`${environment.ppxPrivateApi}api/ToDo/updateVrm/${quoteStateId}/${authType}`).pipe(
      catchError(err => throwError(err))
    );
  }

  getSettings$(category: string): Observable<Array<KeyValuePair>> {
    return this.http.get<Array<KeyValuePair>>(`${environment.ppxPrivateApi}api/settings?category=${category}`)
      .pipe(catchError((err) => throwError(err)));
  }

  findAddress$(postcode: string, houseNumber: string, containerId: string): Observable<Array<AddressSummary>> {
    let queryString = `?postcode=${postcode}`;
    if (houseNumber) {
      queryString = `${queryString}&houseNumber=${houseNumber}`;
    }
    if (containerId) {
      queryString = `${queryString}&containerId=${containerId}`;
    }
    return this.http.get<Array<AddressSummary>>(`${environment.ppxPrivateApi}api/AddressLookup/FindByPostcode${queryString}`)
      .pipe(catchError((err) => throwError(err)));
  }

  retrieveAddress$(id: string): Observable<AddressSummary> {
    return this.http.get<AddressSummary>(`${environment.ppxPrivateApi}api/AddressLookup/RetrieveAddress?id=${id}`)
      .pipe(catchError((err) => throwError(err)));
  }

  verifyBankDetails$(accountNumber: string, sortCode: string, quoteStateId: number): Observable<BankDetails> {
    return this.http.get<BankDetails>(`${environment.ppxPrivateApi}api/Verification/ValidateBankDetails?accountNumber=${accountNumber}&sortCode=${sortCode}&quoteStateId=${quoteStateId}`)
      .pipe(catchError((err) => throwError(err)));
  }

  verifyVatNumber$(quoteStateId: number, vatNumber: string): Observable<Vat> {
    return this.http.get<Vat>(`${environment.ppxPrivateApi}api/Verification/VatCheck?quoteStateId=${quoteStateId}&vatNumber=${vatNumber}`).pipe(
      catchError(err => throwError(err))
    );
  }

  getAppointmentOutcomes$(): Observable<Array<KeyValuePair>> {
    return this.http.get<Array<KeyValuePair>>(`${environment.ppxPrivateApi}api/purchase/outcomes`)
      .pipe(catchError((err) => throwError(err)));
  }

  getNotPurchasedReasons$(): Observable<Array<NotPurchasedReason>> {
    return this.http.get<Array<NotPurchasedReason>>(`${environment.ppxPrivateApi}api/purchase/NotPurchaseReasons`)
      .pipe(catchError((err) => throwError(err)));
  }

  getCompletedStateNames$(processInstanceId: number): Observable<Array<string>> {
    return this.http.get<Array<string>>(`${environment.ppxPrivateApi}api/todo/stateHistory/${processInstanceId}`)
      .pipe(catchError((err) => throwError(err)));
  }

  getManualLookupComponents$(queryString: string): Observable<Array<KeyValuePair>> {
    return this.http.get<Array<KeyValuePair>>(`${environment.ppxPrivateApi}api/MakeAndModel/GetComponentsJson${queryString}`)
      .pipe(catchError((err) => throwError(err)));
  }
}
