<div *ngIf="!hasLookupError" [hidden]="bookingAppointment">
  <div class="modal-header">
    <h4 *ngIf="isStartPurchase && !multipleVehicleDescriptions && !isAltVrm" class="modal-title text-center">Walk-in Purchase</h4>
    <h4 *ngIf="multipleVehicleDescriptions" class="modal-title text-center">Your Vehicle</h4>
    <h4 *ngIf="!multipleVehicleDescriptions && !isStartPurchase && !isAltVrm && !isChangeVrm" class="modal-title text-center">Book Valuation</h4>
    <h4 *ngIf="!multipleVehicleDescriptions && isManualLookup" class="modal-title text-center">&nbsp;- Manual Lookup</h4>
    <h4 *ngIf="isChangeVrm" class="modal-title text-center">Change VRM</h4>
    <h4 *ngIf="!multipleVehicleDescriptions && isAltVrm" class="modal-title text-center">Alt VRM</h4>
    <img class="modal-close" (click)="dismissModal()" alt="Dismiss modal">
  </div>
  <div class="modal-body walk-in-width">
    <div class="container">
      <div class="row">
        <!-- vrm search -->
        <div *ngIf="!multipleVehicleDescriptions && !creatingNewValuation" class="col-12 replace-padding mb-3">
          <app-search-vrm [loading]="isLoading"
                          [displayVrm]="vrm"
                          [vehicleCheckId]="vehicleCheckId"
                          [quoteStateId]="quoteStateId"
                          [isChangeVrm]="isChangeVrm"
                          [altVrm]="isAltVrm"
                          [isStartPurchase]="isStartPurchase"
                          [isBookedVrm]="isBookedVrm"
                          [vrmInput]="altVrm"
                          (vrm)="setVrm($event)"
                          (vehicleDescriptionsResult)="setVehicleDescriptions($event)"
                          (hasLookupError)="setHasLookupError($event)"
                          (isloading)="setIsLoadingForVrmSearch($event)"
                          (hasMissingVrmRelationship)="setHasMissingVrmRelationship($event)"
                          (allowManualLookup)="manualLookup($event)">
          </app-search-vrm>
        </div>

        <!-- vehicle image -->
        <div *ngIf="hasDescription && !creatingNewValuation" class="col-12 replace-padding">
          <app-vehicle-image [vehicleImage]="vehicleImage"
                             [defaultVehicleImage]="defaultVehicleImageUrl"
                             >
          </app-vehicle-image>
        </div>

        <!-- registration plate -->
        <app-registration-plate *ngIf="multipleVehicleDescriptions && !isLoading"
                                class="col-12 replace-padding mb-3 center"
                                [ngClass]="multipleVehicleDescriptions.length == 2 ? 'no-scrollbar' : ''"
                                [vrm]="vrm">
        </app-registration-plate>

        <!-- vehicle description -->
        <div class="col-12 mb-4 replace-padding" *ngIf="!creatingNewValuation">
          <app-vehicle-details *ngIf="vehicleDescription"
                               [vrm]="vrm"
                               [vehicleDescription]="vehicleDescription">
          </app-vehicle-details>
          <app-multiple-derivatives *ngIf="multipleVehicleDescriptions"
                                    [vehicleDescriptions]="multipleVehicleDescriptions"
                                    (selectedDerivativeEmitter)="setSelectedDerivative($event)">
          </app-multiple-derivatives>
        </div>

        <!-- previous valuations field -->
        <div *ngIf="isStartPurchase && isVRMFound && !multipleVehicleDescriptions && !creatingNewValuation" class="col-12 mb-4 replace-padding">
          <app-previous-valuations-lookup [vrm]="vrm"
                                          (hasPreviousValuations)="setHasPreviousValuations($event)"
                                          (selectedPreviousValuationEvent)="setSelectedPreviousValuation($event)"
                                          (pricePromiseEmitter)="setPricePromise($event)"
                                          (isloadingEvent)="setIsLoading($event)">
          </app-previous-valuations-lookup>
        </div>

        <!-- new valuation / retrieve previous valuation buttons -->
        <div class="col-12 replace-padding" *ngIf="isStartPurchase && !isLoading && isVRMFound && !multipleVehicleDescriptions && !creatingNewValuation">
          <div *ngIf="hasPreviousValuations" class="row">
            <div class="col-6">
              <button class="modal-button new-valuation-btn" (click)="newValuation()">
                <span>New Valuation</span>
              </button>
            </div>
            <div class="col-6">
              <button class="modal-button retrieve-valuation-btn" (click)="retrieveValuation()"
                [disabled]="!selectedPreviousValuation" [ngClass]="{'disabled': !selectedPreviousValuation, 'loading': isCreatingPreviousValuationAppointment}">
                <span>Retrieve Valuation</span>
              </button>
            </div>
          </div>
          <div *ngIf="!hasPreviousValuations" class="center">
            <button class="modal-button confirm-btn" (click)="newValuation()">
              <span>Start Valuation</span>
            </button>
          </div>
        </div>
        <div class="col-12 replace-padding" *ngIf="!isStartPurchase && !isLoading && isVRMFound && !multipleVehicleDescriptions && !creatingNewValuation">
          <div class="center">
            <button class="modal-button confirm-btn" (click)="newValuation()">
              <span>Next</span>
            </button>
          </div>
        </div>

        <!-- show valuation questions -->
        <div *ngIf="creatingNewValuation && !isStartPurchase" class="col-12 replace-padding">
          <app-valuation-questions [vrm]="this.vrm"
                                   [altVrm]="this.altVrm"
                                   [bookId]="this.bookId"
                                   [isStartPurchase]="this.isStartPurchase"
                                   [quoteId]="this.vehicleDescription.QuoteId"
                                   [isQuickQuote] ="this.isQuickQuote"
                                   [mileage] = "this.mileage"
                                   [quoteGuid]="this.vehicleDescription.QuoteGuid"
                                   [vehicle]="this.vehicleDescription"
                                   [isBookedVrm]="isBookedVrm"
                                   [userData]="this.userData"
                                   (bookingAppointment)="setBookingAppointment($event)"
                                   (requiresManualValuation)="setRequiresManualValuation($event)"
                                   (highValueRequiresAppointment)="setHighValueRequiresAppointment($event)"
                                   (dismissModal)="dismissModal()"
                                   (customerDetails)="onCustomerDetails($event)">
          </app-valuation-questions>
        </div>
        <div *ngIf="creatingNewValuation && isStartPurchase" class="col-12 replace-padding">
          <app-purchase-questions [vrm]="this.vrm"
                                  [altVrm]="this.altVrm"
                                  [bookId]="this.bookId"
                                  [isBookedVrm]="isBookedVrm"
                                  [requireCustomerName]="true"
                                  [quoteId]="this.vehicleDescription.QuoteId"
                                  [quoteGuid]="this.vehicleDescription.QuoteGuid"
                                  [vehicle]="this.vehicleDescription"
                                  [userData]="this.userData"
                                  (dismissModal)="dismissModal()"
                                  (requiresManualValuation)="setRequiresManualValuation($event)"
                                  (highValueRequiresAppointment)="setHighValueRequiresAppointment($event)">
          </app-purchase-questions>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- vrm not found / HPI error lookup modal -->
<app-lookup-error *ngIf="hasLookupError" [vrm]="vrm" [missingVrmRelationship]="hasMissingVrmRelationship"
  [highValueRequiresAppointment]="highValueRequiresAppointment" [allowManualLookup]="allowManualLookup"
  [isStartPurchase]="this.isStartPurchase" [isQuickQuote]="false" (trySearchAgain)="tryAgain($event)" (bookAppointment)="switchToAppointment($event)">
</app-lookup-error>
