import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EMPTY, Observable } from 'rxjs';
import { tap, catchError, takeUntil } from 'rxjs/operators';
import { Appointment } from '../appointment-details/models/appointment.model';
import { ConfirmDialogService } from '../confirm-dialog/services/confirm-dialog.service';
import { AuthService } from '../core/auth-service';
import { VehicleDetails } from '../models/vehicle-details';
import { AnswerValue } from '../purchase/models/answer-value.model';
import { NotificationService } from '../services/notification.service';
import { PurchaseService } from '../services/purchase.service';
import { VehicleCheck } from '../vehicle-check-hpi/models/vehicle-check.model';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { AuthTask } from '../task-list/models/auth-task.model';
import { AuthorizationRequestService } from '../services/authorization-request.service';
import { ImageService } from '../services/image.service';
import { ImageTypes } from '../enums/image-types';
import { BaseComponentDirective } from '../base/base.component';
import { Router } from '@angular/router';
import { DisplayAnswerComponent } from './display-answer/display-answer.component';
import { AuthorizationTypes } from '../authorization/enums/authorization-types';
import { FlexAuth } from './models/flex-auth.model';
import { PathFinderService } from '../services/path-finder.service';

@Component({
  selector: 'app-appointment-details-auth',
  templateUrl: './appointment-details-auth.component.html',
  styleUrls: ['./appointment-details-auth.component.scss']
})
export class AppointmentDetailsAuthComponent extends BaseComponentDirective implements OnInit {
  vrm: string;
  changeVrm: string;
  altVrm: string;
  vrmHpiDetails: string;

  constructor(
    private modal: NgbActiveModal,
    private purchaseService: PurchaseService,
    private notifications: NotificationService,
    private router: Router,
    private authService: AuthService,
    private modalService: NgbModal,
    private confirmService: ConfirmDialogService,
    private authRequestService: AuthorizationRequestService,
    private imageService: ImageService,
    private sanitizer: DomSanitizer,
    private pathFinder: PathFinderService) { super(); }

  @Input() vehicleDetails: VehicleDetails;
  @Input() mileage: number;
  @Input() appointmentId: number;
  @Input() buyerExpLevel: number;
  @Input() authTask: AuthTask;
  @Input() differentBuyer: boolean;
  @Input() buyerName: string;

  loading: boolean;

  username: string;
  stolen: string;
  finance: string;
  collision: string;
  rkc: string;
  previewString: SafeUrl;
  previewStamp: string;
  quoteStateId: number;
  mileageDecision: string;

  vehicleDetailsCollapsed = true;
  vehicleConditionCollapsed = true;
  mileageDetailsCollapsed = true;
  documentsCollapsed = true;
  vehicleImagesCollapsed = true;
  hasAuthDetailsTab = false;
  activeTab = 0;

  allAnswers: Array<AnswerValue>;
  optionalExtras = new Array<AnswerValue>();
  vehicleCheck: VehicleCheck = null;
  modalData$: Observable<Appointment>;
  appointment: Appointment;

  ApproveText = 'Are you sure you want to\napprove this authorisation?';
  DeclineText = 'Are you sure you want to\ndecline this authorisation?';

  defaultVehicleImage = '../../assets/images/unknownCar.png';
  hpiVehicleDetails: VehicleDetails;
  flexAuth: FlexAuth;
  suggestedWriteOffDesc: string;
  chosenWriteOffDesc: string;
  HPIFirstRegistered: string;

  ngOnInit(): void {
    this.loading = true;
    this.setHasAuthDetailsTab();
    this.appointment = new Appointment();
    this.username = this.authService.getUser()?.name;
    this.modalData$ = this.purchaseService
      .getAuthTaskAppointmentDetail$(this.appointmentId, this.authTask.authorizationTypeId)
      .pipe(
        tap((result) => {
          this.allAnswers = result.answers;
          const isBookedVrm = result.answers.find(item => item.parameterName === 'IsBookedVrm');
          if (isBookedVrm && isBookedVrm.value === 'true') {
            this.vrm = result.vrm;
            this.altVrm = result.vrm;
            this.vrmHpiDetails = result.altVrm;
          } else {
            this.vrm = result.vrm;
            this.altVrm = result.altVrm;
            this.vrmHpiDetails = result.altVrm;
          }
          this.optionalExtras = this.allAnswers.filter(x=>x.isOptionalExtra === true);
          this.quoteStateId = result.quoteStateId;
          result.documents.forEach(doc => {
            if (doc.imgAsBase64String) {
              doc.imgAsBase64String = this.sanitizer.bypassSecurityTrustUrl(doc.imgAsBase64String.toString());
            }
          });

          result.vehicleImages.forEach(vi => {
            if (vi.imgAsBase64String) {
              vi.imgAsBase64String = this.sanitizer.bypassSecurityTrustUrl(vi.imgAsBase64String.toString());
            }
          });
          
          if ((result.source === 'WBAC' || result.source === 'WBAV') ||  result.authorizationRequests.find(x => x.authorizationTypeId === AuthorizationTypes.ChangeVRM)) {
           this.vehicleDetails = new VehicleDetails(
            result.valuation.manufacturer,
            result.valuation.colour,
            result.valuation.model,
            result.valuation.derivative,
            result.valuation.engineSize,
            result.valuation.bookId,
            result.valuation.capId,
            result.valuation.imageUrl,
            result.valuation.fuelType,
            result.valuation.hasSalvage,
            result.valuation.registrationDate
          );
        } else {
          this.vehicleDetails = new VehicleDetails(null, null, null, null, null, 0, 0, null, null, false, null);
        }
          this.mileage = parseInt(result.answers.find(item => item.parameterName === 'Mileage').value, 10);
          const mileageDecisionAnswer = result.answers.find(item => item.parameterName === 'MileageDecision');
          if (mileageDecisionAnswer) {
            this.mileageDecision = mileageDecisionAnswer.value;
          } else {
            // This is a hack until we re-order saving of answers/check auth
            if (this.authTask.authorizationTypeId === AuthorizationTypes.HistoryClearOverride) {
              this.mileageDecision = '1';
            } else if (this.authTask.authorizationTypeId === AuthorizationTypes.DvsaClericalErrorOverride) {
              this.mileageDecision = '205';
            }
          }

          this.hpiVehicleDetails = new VehicleDetails(
            result.previousMake,
            result.previousColour,
            result.previousModel,
            result.previousDerivative,
            result.previousEngineSize,
            result.previousBookId,
            result.previousCapId,
            result.previousImageUrl,
            result.previousFuelType,
            result.valuation.hasSalvage,
            result.previousRegistrationDate
          );
          this.flexAuth = new FlexAuth(result.vehiclePriceOfferedBeforeFlex - result.valuation.adminFee,
            result.valuation.customerPaymentAmount,
            result.valuation.totalDamageCost,
            result.valuation.totalFlexAmount);
          if (this.isInsuranceWriteOff) {
            const descs = this.pathFinder.GetInsuranceWriteOffDescription(result.suggestedWriteOffOption, result.chosenWriteOffOption);
            this.suggestedWriteOffDesc = descs[0];
            this.chosenWriteOffDesc = descs[1];
          }
          this.loading = false;
          this.getHpiDateAnswer();
        }),
        catchError((err) => {
          this.notifications.dangerToast(
            'Failed to retrieve purchase',
            err
          );
          return EMPTY;
        })
      );
  }


  get isHpiMismatch() {
    return this.authTask.authorizationTypeId === AuthorizationTypes.HpiMismatch;
  }

  get isVehicleDetailsChanged() {
    return this.authTask.authorizationTypeId === AuthorizationTypes.VehicleDetailsChanged;
  }

  get isInsuranceWriteOff() {
    return this.authTask.authorizationTypeId === AuthorizationTypes.InsuranceWriteOffOverride;
  }

  get isOptionalExtra() {
    return this.authTask.authorizationTypeId === AuthorizationTypes.OptionalExtra;
  }

  get isFlex() {
    return this.authTask.authorizationTypeId === AuthorizationTypes.FlexBand1 ||
      this.authTask.authorizationTypeId === AuthorizationTypes.FlexBand2 ||
      this.authTask.authorizationTypeId === AuthorizationTypes.SuperFlex;
  }

  get isChangeVrm() {
    return this.authTask.authorizationTypeId === AuthorizationTypes.ChangeVRM;
  }

  get isAltVrm() {
    return this.authTask.authorizationTypeId === AuthorizationTypes.AltVRM;
  }

 

  get authTypeWarningMessage() {
    switch (this.authTask.authorizationTypeId) {
      case AuthorizationTypes.HpiMismatch:
        return 'You are approving the HPI vehicle details to be overridden to the customer selected vehicle';
      case AuthorizationTypes.VehicleDetailsChanged:
        return 'This is a Vehicle Details Change Authorisation';
      case AuthorizationTypes.InsuranceWriteOffOverride:
        return 'You are approving the HPI insurance write off marker to be overridden to the Branch Managers selection';
      case AuthorizationTypes.FlexBand1:
        return 'Flex Band 1 authorisation';
      case AuthorizationTypes.FlexBand2:
        return 'Flex Band 2 authorisation';
      case AuthorizationTypes.SuperFlex:
        return 'Super Flex authorisation';
      case AuthorizationTypes.ChangeVRM:
          return 'Change VRM authorisation';
      case AuthorizationTypes.OptionalExtra:
        return 'You are authorising this car to be purchased with these optional extras';
      case AuthorizationTypes.AltVRM:
          return `This is a Alt VRM Authorisation VRM used for HPI Details = ${this.vrmHpiDetails}`;
      default:
        return '';
    }
  }


  getHpiDateAnswer() {
    this.HPIFirstRegistered = this.pathFinder.getAnswerValue('HPIFirstRegisteredDate', null);
  }

  getOptionDescription(value: string): string {
    const parts = value.split(' - ');
    if (parts.length > 1) {
      return parts[1].replace(', ', ',<br>');
    } else {
      return parts[0].replace(', ', ',<br>');
    }
  }

  getOptionIcon(value: string): string {
    const parts = value.split(' ');
    const first = parts[0].replace('/', '-');
    return '../../assets/icons/write-off/write-off-' + first + '.png';
  }

  private setHasAuthDetailsTab() {
    switch (this.authTask.authorizationTypeId) {
      case AuthorizationTypes.HpiMismatch:
      case AuthorizationTypes.VehicleDetailsChanged:
      case AuthorizationTypes.InsuranceWriteOffOverride:
      case AuthorizationTypes.FlexBand1:
      case AuthorizationTypes.FlexBand2:
      case AuthorizationTypes.SuperFlex:
      case AuthorizationTypes.ChangeVRM:
      case AuthorizationTypes.OptionalExtra:
      case AuthorizationTypes.ChangeOfVehicle:
      case AuthorizationTypes.AltVRM:
        this.hasAuthDetailsTab = true;
        break;
      default:
        this.hasAuthDetailsTab = false;
        break;
    }
  }

  approveAuthTask(task: AuthTask) {
    this.confirmService.showYesNoConfirmation(this.ApproveText,
      () => {
        this.actuallyApproveAuthTask(task);
        this.modal.close();
      }, () => {
      });
  }

  declineAuthTask(task: AuthTask) {
    this.confirmService.showYesNoConfirmation(this.DeclineText,
      () => {
        this.actuallyDeclineAuthTask(task);
        this.modal.close();
      }, () => {
      });
  }

  actuallyApproveAuthTask(task: AuthTask) {
    task.approved = new Date();
    task.authorizedBy = this.buyerName;
    this.authRequestService.updateAuthTask(task);
  }

  actuallyDeclineAuthTask(task: AuthTask) {
    task.declined = new Date();
    task.authorizedBy = this.buyerName;
    this.authRequestService.updateAuthTask(task);
  }

  dismissModal() {
    this.modal.dismiss();
  }

  checkPrivatePlate(privatePlate) {
    if (privatePlate.toLowerCase() === 'false') {
      return false;
    } else {
      return true;
    }
  }

  isPdf(file: string) {
    return file && file.toLowerCase().endsWith('.pdf');
  }

  showPreview(photo, stamp, content) {
    if (photo.vehicleImageId) {
      this.imageService
        .getImagePreview$(photo.vehicleImageId, ImageTypes.VehiclePhoto)
        .pipe(
          catchError((err) => {
            this.notifications.dangerToast(
              'An error occurred while trying to get existing images.',
              err
            );
            return EMPTY;
          }),
          takeUntil(this.componentDestroyed)
        )
        .subscribe((result) => {
          this.previewString =
            this.sanitizer.bypassSecurityTrustUrl(
              result.imgAsBase64String
            );
          this.previewStamp = result.description;
          this.modalService.open(content, { size: 'lg' });
        });
    } else if (photo.documentScanId) {
      this.imageService
        .getImagePreview$(photo.documentScanId, ImageTypes.ScannedDocument)
        .pipe(
          catchError((err) => {
            this.notifications.dangerToast(
              'An error occurred while trying to get existing images.',
              err
            );
            return EMPTY;
          }),
          takeUntil(this.componentDestroyed)
        )
        .subscribe((result) => {
          this.previewString = this.sanitizer.bypassSecurityTrustUrl(
            result.imgAsBase64String
          );
          this.modalService.open(content, { size: 'lg' });
        });
    } else {
      this.previewString = photo.imgAsBase64String;
      this.previewStamp = stamp;
      this.modalService.open(content, { size: 'lg' });
    }
  }

  editVehicleImages() {
    this.router.navigate(['/postPurchase/images', this.quoteStateId]);
  }

  show(answer) {
    const modalRef = this.modalService.open(DisplayAnswerComponent, {
      keyboard: false,
      centered: true,
      size: 'lg',
    });
    modalRef.componentInstance.displayValue = answer.displayValue;
    modalRef.componentInstance.parameterName = answer.parameterName;
  }

  setActiveTab(tabIndex: number) {
    this.activeTab = tabIndex;
  }
}
