import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { PathFinderService } from '../services/path-finder.service';
import { PurchaseService } from '../services/purchase.service';
import { EMPTY, catchError, take, tap } from 'rxjs';
import { NotificationService } from '../services/notification.service';
import { AnswerValue } from '../purchase/models/answer-value.model';
import { LoadingService } from '../services/loading.service';
import { ToDoService } from '../services/to-do.service';

@Component({
  selector: 'app-optional-extras',
  templateUrl: './optional-extras.component.html',
  styleUrls: ['./optional-extras.component.scss']
})
export class OptionalExtrasComponent implements OnInit {

  @Input() quoteStateId: number;
  @Input() questionForm: UntypedFormGroup;
  @Input() vrm: string;
  @Input() group: UntypedFormGroup;
  @Input() stateName: string;

  optionalExtrasCheck: UntypedFormGroup;
  needsAuthorization: boolean;
  optionalExtrasSelected: boolean;

  optionalExtras = new Array<AnswerValue>();
  
  constructor(private pathFinder: PathFinderService,
              private purchaseService: PurchaseService,
              private loadingService: LoadingService,
              private notifications: NotificationService,
              private todoService: ToDoService) { }

  ngOnInit(): void {
    this.loadingService.loading.emit(true);
      this.purchaseService.getOptionalExtras$(this.quoteStateId, this.stateName).pipe(
        tap((result) => {
          if (result !== null) {
            this.optionalExtras = result;
          }
          this.optionalExtras.forEach(element => {
            if (element.isRolledBack) {
              this.valueChanged(element.parameterName, element.value);
            }
          });

          this.loadingService.loading.emit(false);
        }), 
        catchError((err) => {
          this.loadingService.loading.emit(false);
          this.notifications.dangerToast('Failed to retrieve purchase', err);
          return EMPTY;
        }),
        take(1)
      ).subscribe();
      this.createFormGroups();
  }

  valueChanged(parameterName: string, value: string){
    const optExtra = this.optionalExtras.find(x=>x.parameterName === parameterName);
    this.optionalExtras.find(x=>x.parameterName === parameterName).value = JSON.parse(value);
    this.addCheck(parameterName);
    this.saveAnswers(optExtra.parameterName, optExtra.value, optExtra.displayValue);
    this.checkFormValid();
  }

  addCheck(parameterName: string) {
    if (this.optionalExtrasCheck.value.value === null) {
      this.optionalExtrasCheck.value.value = parameterName;
    }else if (!this.optionalExtrasCheck.value.value.toString().includes(parameterName)) {
      this.optionalExtrasCheck.value.value = this.optionalExtrasCheck.value.value + parameterName;
    }
    this.checkOptionalExtrasSelected();
  }

  saveAnswers(parameterName: string, value: string , displayValue: string){
    this.pathFinder.appendAnswer(parameterName, value.toString());
    this.todoService
        .saveAnswerManually$(this.quoteStateId, parameterName, value.toString(), true, displayValue.toString())
        .pipe(
          tap(() => {
          }),
          catchError(() => {
            return EMPTY;
          }),
          take(1)
        )
        .subscribe();
  }

  checkTrue(optionExtra) {
    return typeof optionExtra.value === 'string' && optionExtra.isRolledBack &&  JSON.parse(optionExtra.value) === true;
  }

  checkFalse(optionExtra) {
    return typeof optionExtra.value === 'string' && optionExtra.isRolledBack && JSON.parse(optionExtra.value) === false;
  }

  checkOptionalExtrasSelected() {
    let selected = true;
    const values = this.optionalExtrasCheck.value.value.toString();
    this.optionalExtras.forEach(element => {
      if(!values.includes(element.parameterName)){
         selected = false;
      }
    });
    this.optionalExtrasSelected = selected;
    if(this.optionalExtrasSelected){
      this.group.patchValue({ value: 'valid' });
    } else{
      this.group.patchValue({ value: null });
    }
  }

  checkFormValid() {
    this.needsAuthorization = false;
    this.optionalExtras.forEach(element => {
      if (element.value.toString() === 'true') {
        this.needsAuthorization = true;
      }
    });
    this.pathFinder.appendAnswer('OptionalExtraCheck', this.needsAuthorization ? 'true' : 'false');
  }

  createFormGroups() {
    if (this.questionForm) {
        const controlArray = this.questionForm.get('items') as UntypedFormArray;
        this.optionalExtrasCheck = controlArray.controls[1] as UntypedFormGroup;
    }
}

}
