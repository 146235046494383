import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EMPTY, Observable } from 'rxjs';
import { catchError, map, take, tap } from 'rxjs/operators';
import { KeyValuePair } from '../../models/key-value-pair.model';
import { LookupService } from '../../services/lookup.service';
import { NotificationService } from '../../services/notification.service';
import { ToDoService } from '../../services/to-do.service';
import { VehicleListValue } from '../../walk-in/models/vehicle-list-value.model';
import { ManualLookupComponentTypes } from '../../enums/manual-lookup-component-types';
import { SharedService } from '../../core/shared.service';
import { WalkInComponent } from '../../walk-in/walk-in.component';
import { ConfirmDialogService } from '../../confirm-dialog/services/confirm-dialog.service';
import { QuickQuoteComponent } from '../../quick-quote/quick-quote.component';

@Component({
  selector: 'app-manual-lookup',
  templateUrl: './manual-lookup.component.html',
  styleUrls: ['./manual-lookup.component.scss'],
})
export class ManualLookupComponent implements OnInit {
  @Input() vrm: string;
  @Input() isStartPurchase: boolean;
  @Output() selectedVehicle = new EventEmitter<VehicleListValue>();

  manufactureList: Observable<Array<KeyValuePair>>;
  modelList: Observable<Array<KeyValuePair>>;
  fuelTypeList: Array<KeyValuePair>;
  registrationYearList: Observable<Array<KeyValuePair>>;
  colourList: Observable<Array<KeyValuePair>>;
  derivativeList: Observable<Array<KeyValuePair>>;
  selectedVehicleType: number;
  selectedManufacturer: string;
  selectedModel: string;
  selectedFuelType: string;
  selectedYearOrRegLetter: string;
  selectedColour: string;
  selectedMetallicPaint: number;
  selectedDerivative: string;
  manualLookupForm: UntypedFormGroup;
  vehicle: VehicleListValue;
  selectionList: (string | ManualLookupComponentTypes)[];
  loading = false;
  isMakeLoading = false;
  isModelLoading = false;
  isFuelTypeLoading = false;
  isRegLetterLoading = false;
  isColourLoading = false;
  isDerivativeLoading = false;
  quoteStateId = 0;
  fuelType: string;
  selectFuelType: string;
  allowManualLookup: false;
  isQuickQuote: false;

  constructor(
    private modal: NgbActiveModal,
    private lookupService: LookupService,
    private notificationService: NotificationService,
    private formBuilder: UntypedFormBuilder,
    private todoService: ToDoService,
    private sharedService: SharedService,
    private confirmService: ConfirmDialogService
  ) {}

  ngOnInit(): void {
    this.manualLookupForm = this.formBuilder.group({
      vehicleType: new UntypedFormControl('', [Validators.required]),
      make: new UntypedFormControl({ disabled: true, value: '' }, [Validators.required]),
      model: new UntypedFormControl({ disabled: true, value: '' }, [Validators.required]),
      fuelType: new UntypedFormControl({ disabled: true, value: '' }, [Validators.required]),
      yearOrRegistrationLetter: new UntypedFormControl({ disabled: true, value: '' }, [Validators.required]),
      colour: new UntypedFormControl({ disabled: true, value: '' }, [Validators.required]),
      metallicPaint: new UntypedFormControl({ disabled: true, value: '' }, [Validators.required]),
      derivative: new UntypedFormControl({ disabled: true, value: '' }, [Validators.required]),
    });

    this.selectionList = Object.values(ManualLookupComponentTypes);
  }

  getManualLookupComponents(queryString: string, componentName: string): Observable<Array<KeyValuePair>> {
    this.setLoading(componentName, true);
    return this.lookupService.getManualLookupComponents$(queryString).pipe(
      tap((result) => {
        this.setLoading(componentName, false);
        return result;
      }),
      catchError((err) => {
        this.setLoading(componentName, false);
        this.notificationService.dangerToast('failed to get ' + componentName, err);
        return EMPTY;
      })
    );
  }

  vehicleSelection() {
    this.todoService
      .selectVehicle$(
        this.vrm,
        this.selectedDerivative,
        this.selectedColour,
        this.selectedYearOrRegLetter,
        this.quoteStateId,
        this.fuelType
      )
      .pipe(
        map((result) => {
          this.vehicle = new VehicleListValue(result.vehicleLabelValueLists[0], result.quoteId, result.quoteGuid, this.fuelType, result.hasSalvage);
        }),
        catchError((err) => {
          this.notificationService.dangerToast('Failed to get vehicle identification', err);
          this.loading = false;
          return EMPTY;
        }),
        take(1)
      )
      .subscribe(() => {
        if (this.allowManualLookup) {
          this.isQuickQuote ? this.quickQuote(this.vehicle) : this.walkIn(this.vehicle);
          this.sharedService.setIsManualLookup(true);
        } else {
          this.selectedVehicle.emit(this.vehicle);
          this.sharedService.selectedVehicle.emit(this.vehicle);
        }
        this.loading = false;
        this.modal.close();
      });
  }

  onVehicleSelection(vehicleTypeEvent) {
    if (vehicleTypeEvent.target.value) {
      this.manualLookupForm.get('vehicleType').setValue(vehicleTypeEvent.target.value);
      this.manualLookupForm.get('make').enable();
      this.selectedVehicleType = vehicleTypeEvent.target.value;
      this.resetSelections(ManualLookupComponentTypes.make);
      this.resetSelectionsValue(true, true, true, true, true, true);
      const queryString = `?paramName=Manufacturer&BookId=${this.selectedVehicleType}`;
      this.manufactureList = this.getManualLookupComponents(queryString, 'manufacturer');
    }
  }

  onSelectedManufacturer(makeEvent) {
    if (makeEvent.target.value) {
      this.manualLookupForm.get('make').setValue(makeEvent.target.value);
      this.manualLookupForm.get('model').enable();
      this.selectedManufacturer = makeEvent.target.value;
      this.resetSelections(ManualLookupComponentTypes.model);
      this.resetSelectionsValue(false, true, true, true, true, true);
      const queryString = `?paramName=Model&BookId=${this.selectedVehicleType}&Manufacturer=${this.selectedManufacturer}`;
      this.modelList = this.getManualLookupComponents(queryString, 'model');
    }
  }

  onSelectedModel(modelEvent) {
    if (modelEvent.target.value) {
      this.manualLookupForm.get('model').setValue(modelEvent.target.value);
      this.manualLookupForm.get('fuelType').enable();
      this.selectedModel = modelEvent.target.value;
      this.resetSelections(ManualLookupComponentTypes.fuelType);
      this.resetSelectionsValue(false, false, true, true, true, true);
      const queryString = `?paramName=FuelType&BookId=${this.selectedVehicleType}&Manufacturer=${this.selectedManufacturer}&Model=${this.selectedModel}`;
      this.getManualLookupComponents(queryString, 'fuel type').subscribe((response) => {
        this.fuelTypeList = response;
      });
    }
  }

  onSelectedFuelType(fuelTypeEvent) {
    if (fuelTypeEvent.target.value) {
      this.manualLookupForm.get('fuelType').setValue(fuelTypeEvent.target.value);
      this.manualLookupForm.get('yearOrRegistrationLetter').enable();
      this.selectedFuelType = fuelTypeEvent.target.value;
      this.fuelType = this.fuelTypeList.find((x) => x.key === this.selectedFuelType).value;
      this.resetSelections(ManualLookupComponentTypes.yearOrRegistrationLetter);
      this.resetSelectionsValue(false, false, false, true, true, true);
      const queryString = `?paramName=RegYear&BookId=${this.selectedVehicleType}&Manufacturer=${this.selectedManufacturer}&Model=${this.selectedModel}&FuelType=${this.selectedFuelType}`;
      this.registrationYearList = this.getManualLookupComponents(queryString, 'year or registration number');
    }
  }

  onSelectedYearOrRegLetter(yearRegOrLetterEvent) {
    if (yearRegOrLetterEvent.target.value) {
      this.manualLookupForm.get('yearOrRegistrationLetter').setValue(yearRegOrLetterEvent.target.value);
      this.manualLookupForm.get('colour').enable();
      this.selectedYearOrRegLetter = yearRegOrLetterEvent.target.value;
      this.resetSelections(ManualLookupComponentTypes.colour);
      this.resetSelectionsValue(false, false, false, false, true, true);
      const queryString = `?paramName=Colour&BookId=${this.selectedVehicleType}&Manufacturer=${this.selectedManufacturer}&Model=${this.selectedModel}&FuelType=${this.selectedFuelType}&RegYear=${this.selectedYearOrRegLetter}`;
      this.colourList = this.getManualLookupComponents(queryString, 'colour');
    }
  }

  onSelectedColour(colourEvent) {
    if (colourEvent.target.value) {
      this.manualLookupForm.get('colour').setValue(colourEvent.target.value);
      this.manualLookupForm.get('metallicPaint').enable();
      this.selectedColour = colourEvent.target.value;
      this.resetSelections(ManualLookupComponentTypes.metallicPaint);
      this.resetSelectionsValue(false, false, false, false, false, true);
    }
  }

  onMetallicPaintSelected(metallicPaintEvent) {
    if (metallicPaintEvent.target.value) {
      this.manualLookupForm.get('metallicPaint').setValue(metallicPaintEvent.target.value);
      this.manualLookupForm.get('derivative').enable();
      this.selectedMetallicPaint = metallicPaintEvent.target.value;
      this.resetSelections(ManualLookupComponentTypes.derivative);
      this.resetSelectionsValue(false, false, false, false, false, false);
      const queryString = `?paramName=Derivative&BookId=${this.selectedVehicleType}&Manufacturer=${this.selectedManufacturer}&Model=${this.selectedModel}&FuelType=${this.selectedFuelType}&RegYear=${this.selectedYearOrRegLetter}&Colour=${this.selectedColour}&MetallicPaint=${this.selectedMetallicPaint}`;
      this.derivativeList = this.getManualLookupComponents(queryString, 'derivative');
    }
  }

  onSelectedDerivative(derivativeEvent) {
    if (derivativeEvent.target.value) {
      this.manualLookupForm.get('derivative').setValue(derivativeEvent.target.value);
      this.selectedDerivative = derivativeEvent.target.value;
    }
  }

  resetSelectionsValue(
    resetMake: boolean,
    resetModel: boolean,
    resetFuelType: boolean,
    resetYearOrRegLetter: boolean,
    resetColour: boolean,
    resetMetallicPaint: boolean
  ) {
    this.selectedManufacturer = resetMake ? null : this.selectedManufacturer;
    this.selectedModel = resetModel ? null : this.selectedModel;
    this.selectedFuelType = resetFuelType ? null : this.selectedFuelType;
    this.selectedYearOrRegLetter = resetYearOrRegLetter ? null : this.selectedYearOrRegLetter;
    this.selectedColour = resetColour ? null : this.selectedColour;
    this.selectedMetallicPaint = resetMetallicPaint ? null : this.selectedMetallicPaint;
    this.selectedDerivative = null;
  }

  resetSelections(keepSelectionEnabled: string) {
    const selectionIndex = this.selectionList.indexOf(keepSelectionEnabled);
    const copyList = Array.from(this.selectionList);
    const selections = copyList.splice(selectionIndex, copyList.length);

    selections.forEach((x) => {
      this.manualLookupForm.get(x).patchValue('');
      if (x !== keepSelectionEnabled) {
        this.manualLookupForm.get(x).disable();
      }
    });
  }

  onSubmitManualLookupForm() {
    if (this.manualLookupForm.value) {
      this.loading = true;
      this.vehicleSelection();
    }
  }

  closeModal() {
    this.modal.dismiss();
  }

  setLoading(property: string, isLoading: boolean) {
    if (property === 'manufacturer') {
      this.isMakeLoading = isLoading;
    } else if (property === 'model') {
      this.isModelLoading = isLoading;
    } else if (property === 'fuel type') {
      this.isFuelTypeLoading = isLoading;
    } else if (property === 'year or registration number') {
      this.isRegLetterLoading = isLoading;
    } else if (property === 'colour') {
      this.isColourLoading = isLoading;
    } else if (property === 'derivative') {
      this.isDerivativeLoading = isLoading;
    }
  }

  walkIn(vehicleDescriptions) {
    const modalRef = this.confirmService.redirectToModal(WalkInComponent);
    modalRef.componentInstance.isStartPurchase = this.isStartPurchase;
    modalRef.componentInstance.hasDescription = true;
    modalRef.componentInstance.vehicleImage = vehicleDescriptions.ImageUrl;
    modalRef.componentInstance.defaultVehicleImageUrl = vehicleDescriptions.DefaultVehicleImageUrl;
    modalRef.componentInstance.vrm = vehicleDescriptions.Vrm;
    if (vehicleDescriptions) {
      modalRef.componentInstance.vehicleDescription = vehicleDescriptions;
      modalRef.componentInstance.creatingNewValuation = true;
    }
    modalRef.componentInstance.isLoading = false;
    modalRef.componentInstance.isChangeVrm = false;
  }

  quickQuote(vehicle: VehicleListValue) {
    const modalRef = this.confirmService.redirectToModal(QuickQuoteComponent);
    modalRef.componentInstance.isVRMFound = vehicle !== null;
    modalRef.componentInstance.selectedGrade = null;
    modalRef.componentInstance.creatingNewValuation = false;
    modalRef.componentInstance.vehicleImage = vehicle.ImageUrl;
    modalRef.componentInstance.defaultVehicleImageUrl = vehicle.DefaultVehicleImageUrl;
    modalRef.componentInstance.vehicleDescription = vehicle;
    modalRef.componentInstance.vrm = vehicle.Vrm;
    modalRef.componentInstance.showQuickQuoteValuation = false;
  }
}
