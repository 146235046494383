
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CustomValidators } from '../custom-validators/custom-validators';
import { LookupService } from '../services/lookup.service';
import { EMPTY, catchError, take, tap } from 'rxjs';
import { ToDoService } from '../services/to-do.service';
import { LoadingService } from '../services/loading.service';
@Component({
  selector: 'app-vrm-confirmation-modal',
  templateUrl: './vrm-confirmation-modal.component.html',
  styleUrls: ['./vrm-confirmation-modal.component.scss'],
})
export class VrmConfirmationModalComponent implements OnInit {
  @Input() appointmentVrm: string;
  @Input() quoteStateId: number;
  @Output() vrmMatches = new EventEmitter<boolean>();

  vrmEntered: string;
  vrmHasBeenCheckedAndDoesNotMatch: boolean;
  vrmConfirmed: boolean;
  vrmSearchForm: FormGroup;
  displayImageUrl: string;
  isConfirmDialog = false;
  noInput: boolean;
  loading: boolean;
 
  constructor(
    private modalService: NgbModal,
    private lookupService: LookupService,
    private formBuilder: FormBuilder,
    private todoService: ToDoService,
    private loadingService: LoadingService
  ) { }

  ngOnInit(): void {
    this.vrmSearchForm = this.formBuilder.group({
      vrm: [
        '',
        [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(8),
          Validators.pattern(CustomValidators.vrmRegex),
        ],
      ],
    });
  }

  dismissModal() {
    this.modalService.dismissAll();
  }

  checkVrm() {
    this.vrmEntered = this.vrmSearchForm.get('vrm').value;
    const vrmEnteredNoWhitespace = this.vrmEntered
      .replace(/\s/g, '')
      .toLowerCase();
    const appointmentVrmNoWhitespace = this.appointmentVrm
      .replace(/\s/g, '')
      .toLowerCase();
    if (vrmEnteredNoWhitespace !== appointmentVrmNoWhitespace) {
      this.vrmHasBeenCheckedAndDoesNotMatch = true;
      this.isConfirmDialog = true;
    } else {
      this.modalService.dismissAll();
      this.vrmMatches.emit(true);
    }
  }

  vrmIsCorrect() {
    this.loading = true;
      this.lookupService
        .vehicleCheckRelatedVrms$(
          this.appointmentVrm.toUpperCase(),
          this.vrmEntered.toUpperCase(),
          this.quoteStateId,
          false
        )
        .pipe(
          tap((vrmRelated) => {
            this.loading = false;
            if (vrmRelated) {
              this.modalService.dismissAll();
              this.todoService
                .saveAnswerManually$(this.quoteStateId, 'ChangeVrm', 'true', false)
                .pipe(
                  tap(() => {
                    this.vrmMatches.emit(true);
                  }),
                  catchError(() => {
                    this.loading = false;
                    return EMPTY;
                  }),
                  take(1)
                )
                .subscribe();
            } else {
              this.vrmConfirmed = true;
              this.isConfirmDialog = false;
              this.vrmHasBeenCheckedAndDoesNotMatch = false;
            }
          })
        )
        .subscribe();
    
  }

  reEnterVrm() {
    this.vrmHasBeenCheckedAndDoesNotMatch = false;
    this.vrmConfirmed = false;
    this.isConfirmDialog = false;
    this.vrmSearchForm.setValue({ vrm: '' });
  }

  allowOnlyAlphanumerics(inputVrm: string) {
    const inputLength = inputVrm.length;
    this.noInput = inputLength === 0;
    if (!this.noInput) {
      const isMaxInput = inputLength > 8;
      if (!CustomValidators.vrmRegex.test(inputVrm.charAt(inputLength - 1)) || isMaxInput) {
        inputVrm = inputVrm.slice(0, -1);
        this.vrmSearchForm.patchValue({
          vrm: inputVrm
        });
      }
    }
  }
}
