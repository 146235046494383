import { Component, Input, AfterViewInit, OnInit } from '@angular/core';
import { VehicleDetails } from '../../models/vehicle-details';
import { VehicleListValue } from '../../walk-in/models/vehicle-list-value.model';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-vehicle-details',
  templateUrl: './vehicle-details.component.html',
  styleUrls: ['./vehicle-details.component.scss'],
})
export class VehicleDetailsComponent implements OnInit, AfterViewInit {

  @Input() questionForm: UntypedFormGroup;
  @Input() vrm: string;
  @Input() vehicleDescription: VehicleListValue;
  @Input() vehicleDetails: VehicleDetails;
  @Input() mileage: string;
  @Input() hideDetails: boolean;
  @Input() altStyle: number;
  @Input() HPIFirstRegistered: string;
  @Input() isUserEnteredDate: string;
  @Input() isHPIDate: string;

  hasCapIdDateDiscrepancy: boolean;
  firstRegisteredDate: Date;
  HPIFirstRegisteredDate: Date;
  firstRegisteredDateDisplay: string;
  HPIFirstRegisteredDateDisplay: string;
  hasSalvage = '';

  constructor() { }


  ngOnInit(): void {
    if (this.vehicleDescription) {
      this.hasSalvage = this.vehicleDescription.HasSalvage ? 'Salvage Auction' : this.booleanIcon(this.vehicleDescription.HasSalvage);
    }
    if(this.vehicleDetails?.colour === null) {
      this.mileage = null;
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (this.questionForm) {
        this.updateQuestionFormValue(this.questionForm);
      }
    });
  }

  updateTextColor() {
    if (this.hasCapIdDateDiscrepancy && !this.isHPIDate && !this.isUserEnteredDate) {
      this.altStyle = 4
    }
  }


  updateQuestionFormValue(question: UntypedFormGroup) {
    question.controls.value.setValue(true);
  }

  get engineFormat() {
    if (this.vehicleDetails?.engineSize) {
      return this.vehicleDetails.fuelType
        ? `${this.vehicleDetails.engineSize}cc - ${this.vehicleDetails.fuelType}`
        : `${this.vehicleDetails.engineSize}cc`;
    } else if(this.vehicleDescription?.EngineSize) {
      return `${this.vehicleDescription.EngineSize}cc`;
    } else {
      return null;
    }
  }

  get modelFormat() {
    const data = '';
    if (this.vehicleDetails?.model) {
      return `${this.vehicleDetails.model} - ${this.vehicleDetails.derivative}`;
    } else if(this.vehicleDescription?.Model) {
      return `${this.vehicleDescription.Model}`;
    }
    return data;
  }

  booleanIcon(value) {
    if (value === true) {
      return '../../assets/icons/buttons/trueTick.svg';
    } else if (value === false) {
      return '';
    } else {
      return '../../assets/icons/buttons/redDash.svg';
    }
  }

  get capData() {
    let data = '';
    if (this.vehicleDetails.bookId) {
      switch (this.vehicleDetails.bookId) {
        case 1:
          data = `Car - ${this.vehicleDetails.capId}`;
          break;

        case 2:
          data = `Van - ${this.vehicleDetails.capId}`;
          break;
      }
    }
    if (this.vehicleDescription?.CapCode && data === '') {
      data = `${this.vehicleDescription.CapCode.endsWith('L') ? 'Van' : 'Car'} - ${this.vehicleDescription.CapCodeId}`;
    }

    return data;
  }
}

