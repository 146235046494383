<div class="modal-header">
    <img class="modal-close" (click)="altCloseModal()" alt="Close modal" />
    <h4 class="modal-title text-center obfuscated-playback">{{ title }}</h4>
</div>
<ng-container *ngIf="!altVrmFlow">
    <div class="modal-body obfuscated-playback">
        <img *ngIf="displayImageUrl" class="mt-4 mb-3" src="{{ displayImageUrl }}" alt="Warning" />
        <app-registration-plate *ngIf="title !== 'Unable to claim'" [isStartPurchase]='true' [vrm]='vrm' [isHpiModal]="true"></app-registration-plate>
        <div class="modal-body__message mt-1 mb-4">{{ message }}</div>
    </div>
    <div class="modal-footer">
        <button class="modal-body__button-confirm button_red" *ngIf="isYesNo || isYes" (click)="activeModal.dismiss()">{{ isYes ? 'No' : 'Cancel'}}</button>
        <span class="me-2" *ngIf="isYesNo"></span>
        <button class="modal-body__button-confirm button_green" *ngIf="isYes || isYesNo" (click)="closeModal()">{{
            isYes ? title == 'Change VRM' ? 'Not My Car' : 'Yes' : 'Ok' }}</button>
        <button class="modal-body__button-confirm button_green" *ngIf="isOk" (click)="activeModal.dismiss()">{{
            'Ok'}}</button>
        <button class="hpi-vrm-btn" *ngIf="!isYesNo && !isOk && !isYes" (click)="dismissModal()">VRM is Correct</button>
    </div>
</ng-container>


<ng-container *ngIf="altVrmFlow">
    <div class="modal-footer alt-footer">
        <div class="modal-body__message mt-1">{{ message }}</div>
        <app-registration-plate [isStartPurchase]='true' [vrm]='vrm' [isHpiModal]="true" (plateSelected)="plateSelected($event)"></app-registration-plate>
        <div class="or_div">
            <p class="selection-plate">Or</p>
        </div>
        <app-registration-plate [isStartPurchase]='true' [vrm]='altVrm' [isHpiModal]="true" (plateSelected)="plateSelected($event)"></app-registration-plate>
    </div>
</ng-container>