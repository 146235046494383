import { Injectable } from '@angular/core';
import { EMPTY, forkJoin, Observable } from 'rxjs';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import moment from 'moment';
import { AppointmentService } from '../../services/appointment.service';
import { BookAppointmentComponent } from '../book-appointment.component';
import { BookAppointmentViewTypes } from '../enums/book-appointment-view-types';
import { NotificationService } from '../../services/notification.service';
import { SitesAndSlotsByPostcode } from '../models/sites-and-slots-by-postcode.model';
import { TimeSlot } from '../models/time-slot.model';
import { ValuationService } from '../../services/valuation.service';
import { ValuationSummary } from '../../damage/models/valuation-summary.model';
import { VehicleCheckService } from '../../services/vehicle-check.service';
import { WalkInLookupData } from '../../walk-in/models/walk-in-lookup-data.model';
import { TaskTypes } from '../../task-list/enums/task-types';
import { ActiveSiteDays } from '../models/active-site-days.model';

@Injectable({ providedIn: 'root' })

export class BookAppointmentService {

  vrm: string;
  walkInLookupData: WalkInLookupData;
  bookId: number;
  motExpiryDate: string;
  defaultMotExpiryDate = '0001-01-01';
  appointmentSlots: SitesAndSlotsByPostcode;
  timeSlots: Array<TimeSlot>;
  valuation: ValuationSummary;
  view: BookAppointmentViewTypes;
  bookAppointmentView = BookAppointmentViewTypes;
  maximumValueAppointmentDelayInDays: number;
  cancelledAppointment: boolean;
  appointmentId: number;
  taskType: string;
  todayDate = new Date();
  activeSites = new Array<ActiveSiteDays>();
  activeSiteIds = new Array<number>();

  constructor(
    private appointmentService: AppointmentService,
    private notifications: NotificationService,
    private modalService: NgbModal,
    private valuationService: ValuationService,
    private vehicleCheckService: VehicleCheckService) { }

  taskDetails(appointmentId: number, taskType: TaskTypes) {
    this.appointmentId = appointmentId;
    this.taskType = taskType;
  }

  nextDate(dayIndex) {
    const today = new Date();
    const isSunday = today.getDay();
    if (isSunday === 7) {
      return today;
    }
    today.setDate(today.getDate() + (dayIndex - 1 - today.getDay() + 7) % 7 + 1);
    return today;
  }

  getBuyerActiveSites() {
    this.appointmentService.getBuyerWeeklySchedule(moment(this.todayDate).format('YYYY-MM-DD')).pipe(
      tap(result => {
        const sites = result.sites.map(x => x.siteDays.map(y => [y.siteId, y.dayId]));
        sites.forEach(element => {
          element.forEach(field => {
            this.activeSites.push(new ActiveSiteDays(field[0], field[1]));
          });
        });
        this.activeSiteIds = [...new Set(this.activeSites.map(item => item.siteId))];
      }),
      catchError(err => {
        this.notifications.dangerToast('Failed to get buyer weekly schedule', err);
        return EMPTY;
      }), take(1)).subscribe();
  }

  openBookAppointmentModal$(postcode: string, walkInLookupData: WalkInLookupData, bookId: number,
     isReschedule: boolean = false, cancelledAppointment: boolean = false, altVrm: string ='', isBookedVrm: boolean = false,): Observable<NgbModalRef> {
    this.getBuyerActiveSites();
    this.walkInLookupData = walkInLookupData;
    this.bookId = bookId;
    this.cancelledAppointment = cancelledAppointment;
    return forkJoin([
      this.getMotExpiryDate$(),
      this.getValuation$()
    ]).pipe(switchMap(() =>
      this.appointmentService.getAppointmentSlots$(postcode, this.valuation.vehiclePriceOffered, this.motExpiryDate, this.bookId)
        .pipe(
          tap(result => {
            this.appointmentSlots = result;
            if (isReschedule) {
              for (let index = 0; index < this.appointmentSlots.sites.length; index++) {
                const element = this.appointmentSlots.sites[index];
                if (!this.activeSiteIds.includes(element.siteId)) {
                  this.appointmentSlots.sites.splice(index, 1);
                  index--;
                }
              }

              const sundayDt = this.nextDate(0);
              this.appointmentSlots.sites.forEach(site => {
                site.siteDates.forEach(siteDate => {
                  let disable = true;
                  const formattedDate = new Date(siteDate.date);
                  const dayId = formattedDate.getDay();
                  this.activeSites.forEach(element => {
                    if (element.day === dayId && element.siteId === site.siteId && formattedDate < sundayDt) {
                      disable = false;
                    }
                  });
                  if (disable) {
                    siteDate.appointmentsCount = 1;
                    siteDate.totalAppointmentSlotsCount = 0;
                  }
                });
              });
            }
            this.maximumValueAppointmentDelayInDays = result.maximumValueAppointmentDelayInDays;
          }),
          catchError(err => {
            this.notifications.dangerToast('failed to get appointment slots', err);
            throw err;
          })
        ))).pipe(map(() =>
          this.displayBookAppointmentModal(isReschedule, altVrm, isBookedVrm)
        ));
  }

  displayBookAppointmentModal(isReschedule: boolean,  altVrm: string, isBookedVrm: boolean): NgbModalRef {
    const modalRef = this.modalService.open(BookAppointmentComponent, {
      keyboard: false,
      backdrop: 'static',
      centered: true,
      size: 'lg'
    });

    modalRef.componentInstance.appointmentSlots = this.appointmentSlots;
    modalRef.componentInstance.walkInLookupData = this.walkInLookupData;
    modalRef.componentInstance.valuation = this.valuation;
    modalRef.componentInstance.bookId = this.bookId;
    modalRef.componentInstance.altVrm = altVrm;
    modalRef.componentInstance.isBookedVrm = isBookedVrm;
    modalRef.componentInstance.maximumValueAppointmentDelayInDays = this.maximumValueAppointmentDelayInDays;
    modalRef.componentInstance.cancelledAppointment = this.cancelledAppointment;
    modalRef.componentInstance.isReschedule = isReschedule;
    return modalRef;
  }

  getMotExpiryDate$(): Observable<Date> {
    return this.vehicleCheckService.getLastMotExpiryDate$(this.walkInLookupData.vrm)
      .pipe(
        tap(result => {
          if (!result) {
            this.motExpiryDate = this.defaultMotExpiryDate;
          } else {
            this.motExpiryDate = moment(result).format('YYYY-MM-DD');
          }
        }),
        catchError(err => {
          this.notifications.dangerToast('Failed to get MOT expiry date', err);
          throw err;
        })
      );
  }

  getValuation$(): Observable<ValuationSummary> {
    return this.valuationService.performPreBookingValuation$(this.walkInLookupData)
      .pipe(
        tap(result => {
          this.valuation = result;
        }),
        catchError(err => {
          this.notifications.dangerToast('Failed to get pre-booking valuation', err);
          throw err;
        })
      );
  }

  getMotExpiryDateValue(): string {
    return this.motExpiryDate;
  }

  getValuationSummary(): ValuationSummary {
    return this.valuation;
  }
}
