import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-alt-vrm',
  templateUrl: './alt-vrm.component.html'
})
export class AltVrmComponent {
  @Input() vrm: string;
  @Input() quoteStateId: number;
  @Input() bookedWithNoHpi: boolean;
  @Output() canPurchase: EventEmitter<boolean>;

  altVrm: string;
  hasLookupError = true;
  isAlt = true;
  hasMissingVrmRelationship: boolean;

  constructor(private modal: NgbActiveModal) { }

  dismissModal() {
    this.modal.dismiss();
  }

  setHasLookupError(hasLookupError: boolean) {
    this.hasLookupError = hasLookupError;
    if (!this.hasLookupError) {
      this.modal.close();
    }
  }

  setHasMissingVrmRelationship(hasMissingVrmRelationship: boolean) {
    this.hasMissingVrmRelationship = hasMissingVrmRelationship;
    this.hasLookupError = hasMissingVrmRelationship;
    if(this.bookedWithNoHpi && hasMissingVrmRelationship) {
      this.bookedWithNoHpi = false;
    }
  }

  setVrm(vrm: string) {
    if (this.isAlt) {
      this.altVrm = vrm;
    } else {
      this.vrm = vrm;
    }
  }

  tryAgain(isAlt: boolean) {
    this.isAlt = isAlt;
    this.hasLookupError = false;
    this.hasMissingVrmRelationship = false;
  }
}
