import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-vehicle-image',
  templateUrl: './vehicle-image.component.html',
  styleUrls: ['./vehicle-image.component.scss'],
})

export class VehicleImageComponent implements OnInit {
  @Input() vehicleImage: string;
  @Input() defaultVehicleImage: string;
  @Input() selectedGrade: number;
  @Input() showGrade: boolean;
  @Input() isStartPurchase: boolean;
  unknownImage: boolean;

  ngOnInit() {
    if (!this.vehicleImage || this.vehicleImage.length === 0) {
      this.setVehicleImageDefault();
    }
  }

  setVehicleImageDefault() {
    this.vehicleImage = this.defaultVehicleImage;
  }
}
