<div class="modal-header">
    <h4 *ngIf="!vrmHasBeenCheckedAndDoesNotMatch && !vrmConfirmed" class="modal-title text-center">Confirm VRM on the vehicle</h4>
    <h4 *ngIf="vrmHasBeenCheckedAndDoesNotMatch" class="modal-title text-center">VRM Check</h4>
    <h4 *ngIf="vrmConfirmed" class="modal-title text-center">Unable to Purchase</h4>
    <img class="modal-close" (click)="dismissModal()" alt="Close modal">
</div>
<div [ngClass]="(!vrmHasBeenCheckedAndDoesNotMatch && !vrmConfirmed) ? 'search-vrm-modal-width' : 'modal-width'" class="modal-body">
    <form *ngIf="!vrmHasBeenCheckedAndDoesNotMatch && !vrmConfirmed" [formGroup]="vrmSearchForm" (ngSubmit)="checkVrm()" class="search-form">
        <input class="form-control input-text" formControlName="vrm" maxlength="8" placeholder='Enter VRM' [ngClass]="!noInput ? !vrmSearchForm.valid ? 'invalid' : '' : ''"
            (ngModelChange)="allowOnlyAlphanumerics($event)" autocomplete="off" required tabindex="-1" />
        <span class="placeholder-line"></span>
        <button class="search-button modal-button button_green" [ngClass]="{'invalid':!vrmSearchForm.valid, 'loading': loading}" [disabled]="!vrmSearchForm.valid">Search</button>
    </form>
    <div>
        <div *ngIf="vrmConfirmed">
            <img src="{{ displayImageUrl }}" />
        </div>
        <app-registration-plate *ngIf="vrmHasBeenCheckedAndDoesNotMatch || vrmConfirmed" [isStartPurchase]='true' [vrm]='vrmEntered' [isHpiModal]="false"></app-registration-plate>
        <div *ngIf="isConfirmDialog">
            <p>
                Are you sure that this is the VRM on the vehicle?
            </p>
        </div>
        <div *ngIf="vrmConfirmed">
            <p>
                We will be unable to purchase the vehicle due to no relationship with the VRM.
            </p>
        </div>
    </div>
    <div *ngIf="isConfirmDialog" class="row">
        <div class="col-6">
            <button class="modal-button vrm-correct-btn" (click)="vrmIsCorrect()">
                <span>VRM is correct</span>
            </button>
        </div>
        <div class="col-6">
            <button class="modal-button re-enter-vrm-btn" (click)="reEnterVrm()">
                <span>Re-Enter VRM</span>
            </button>
        </div>
    </div>
    <div *ngIf="vrmConfirmed" class="row">
        <div class="col-6 offset-3">
            <button class="modal-button ok-btn" (click)="dismissModal()">
                <span>Ok</span>
            </button>
        </div>
    </div>
</div>

<ng-container *ngIf="loading">
    <app-loading-wbac-animation></app-loading-wbac-animation>
  </ng-container>