import { Injectable } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmDialogComponent } from '../confirm-dialog.component';
import { EMPTY, from } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ConfirmOptions, createConfirmOptions } from '../models/confirm-options.model';
import { ConfirmDialogImageTypes } from '../enums/confirm-dialog-image-types';
import { Stock } from '../../stock/models/stock.model';
import { ConfirmOptionsList, createConfirmOptionsWithMessageItems } from '../models/confirm-options-list.model';
import { ConfirmDialogListComponent } from '../confirm-dialog-list.component';
import { HpiModalComponent } from '../../hpi-modal/hpi-modal.component';
import { WalkInComponent } from '../../walk-in/walk-in.component';
import { Router } from '@angular/router';
import { ManualLookupComponent } from '../../search-vrm/manual-lookup/manual-lookup.component';
@Injectable({ providedIn: 'root' })
export class ConfirmDialogService {
  constructor(private modalService: NgbModal, private router: Router) { }

  confirmBookAppointment() {
    const displayImageUrl = ConfirmDialogImageTypes.thumbsUpUrl;
    const message = 'Thanks, that appointment has\nbeen booked and we’ll send a\ncopy to the customer’s email';
    const options = createConfirmOptions(message, displayImageUrl, false);
    return this.displayConfirmDialog(options);
  }

  confirmCancellation() {
    const displayImageUrl = ConfirmDialogImageTypes.questionMarkUrl;
    const message = 'Are you sure you\nwould like to cancel?';
    const options = createConfirmOptions(message, displayImageUrl, false);
    return this.displayConfirmDialog(options);
  }

  confirmAppointmentCancellation() {
    const displayImageUrl = ConfirmDialogImageTypes.thumbsUpUrl;
    const message = 'Thanks, that appointment has\nbeen cancelled';
    const options = createConfirmOptions(message, displayImageUrl, false, true);
    return this.displayConfirmDialog(options);
  }

  confirmPurchaseCancellation() {
    const displayImageUrl = ConfirmDialogImageTypes.cancelledPurchaseUrl;
    const message = 'The purchase has been cancelled';
    const options = createConfirmOptions(message, displayImageUrl, false);
    return this.displayConfirmDialog(options);
  }

  confirmManualAddress() {
    const displayImageUrl = ConfirmDialogImageTypes.questionMarkUrl;
    const message =
      'We can\'t find the address,\ncan you enter it manually, please?';
    const options = createConfirmOptions(message, displayImageUrl, false);
    return this.displayConfirmDialog(options);
  }

  saveStockStockCheckConfirmation(numberOfNonRunners: number, nonRunners: Array<Stock>) {
    const displayImageUrl = ConfirmDialogImageTypes.questionMarkUrl;
    const message = `Are you sure you want to report:`;
    const messageItems = nonRunners.map(x => x.vrm + ' - ' + x.model + ' ' + x.make);
    const messageFooter = 'as Non-Runner(s) and send this report?';
    const options = createConfirmOptionsWithMessageItems(message, displayImageUrl, false, messageItems, messageFooter);
    return this.displayConfirmDialogList(options);
  }

  saveDailyStockCheck() {
    const displayImageUrl = ConfirmDialogImageTypes.thumbsUpUrl;
    const message =
      'Thanks, that report has been sent. The Branch Stock Inventory will become available.';
    const options = createConfirmOptions(message, displayImageUrl, false);
    return this.displayConfirmDialog(options);
  }

  saveStockInventory(numberOfNonRunners: number, nonRunners: Array<Stock>) {
    const displayImageUrl = ConfirmDialogImageTypes.questionMarkUrl;
    let message = 'Are you sure you would like to send this report?';
    if (numberOfNonRunners > 0) {
      message = `Are you sure you want to report:`;
      const messageItems = nonRunners.map(x => x.vrm + ' - ' + x.model + ' ' + x.make);
      const messageFooter = 'as Non-Runner(s) and send this report?';
      const options = createConfirmOptionsWithMessageItems(message, displayImageUrl, false, messageItems, messageFooter);
      return this.displayConfirmDialogList(options);
    } else {
      const options = createConfirmOptions(message, displayImageUrl, false);
      return this.displayConfirmDialog(options);
    }
  }

  showYesNoConfirmation(message: string, onYes: () => void, onNo: () => void) {
    const displayImageUrl = ConfirmDialogImageTypes.questionExclamationMarkUrl;
    const options = createConfirmOptions(message, displayImageUrl, true);
    const modalRef = this.createConfirmDialog(options);
    modalRef.result.then(
      () => { onYes(); },
      () => { onNo(); }
    );
  }

  async showYesNoConfirmationAsync(message: string) {
    const displayImageUrl = ConfirmDialogImageTypes.questionMarkUrl;
    const options = createConfirmOptions(message, displayImageUrl, true);
    const modalRef = this.createConfirmDialog(options);
    let confirm: boolean;
    await modalRef.result.then(
      () => { confirm = true; },
      () => { confirm = false; }
    );

    return confirm;
  }

  confirmAuthorizationRequest(authorizationType: string, onYes: () => void, onNo: () => void) {
    const displayImageUrl = ConfirmDialogImageTypes.questionMarkUrl;
    const message = 'Do you want to send an authorisation request for the\n' + authorizationType + '?';
    const options = createConfirmOptions(message, displayImageUrl, true);
    const modalRef = this.createConfirmDialog(options);
    modalRef.result.then(
      () => { onYes(); },
      () => { onNo(); }
    );
  }

  async confirmAuthorizationRequestAsync(authorizationType: string) {
    const displayImageUrl = ConfirmDialogImageTypes.questionMarkUrl;
    const message = 'Do you want to send an authorisation request for the\n' + authorizationType + '?';
    const options = createConfirmOptions(message, displayImageUrl, true);
    const modalRef = this.createConfirmDialog(options);
    let confirm: boolean;
    await modalRef.result.then(
      () => { confirm = true; },
      () => { confirm = false; }
    );

    return confirm;
  }

  private createConfirmDialog(options: ConfirmOptions): NgbModalRef {
    const modalRef = this.modalService.open(ConfirmDialogComponent, {
      keyboard: false,
      backdrop: 'static',
      centered: true,
      size: 'sm',
    });
    modalRef.componentInstance.displayImageUrl = options.displayImageUrl;
    modalRef.componentInstance.message = options.message;
    modalRef.componentInstance.isYesNo = options.yesAndNoButtons;
    modalRef.componentInstance.closeAllWindows = options.closeAllWindows;
    return modalRef;
  }

  private displayConfirmDialog(options: ConfirmOptions) {
    const modalRef = this.createConfirmDialog(options);
    return from(modalRef.result).pipe(catchError(() => EMPTY));
  }

  private createConfirmDialogList(options: ConfirmOptionsList): NgbModalRef {
    const modalRef = this.modalService.open(ConfirmDialogListComponent, {
      keyboard: false,
      backdrop: 'static',
      centered: true,
      size: 'sm',
    });
    modalRef.componentInstance.displayImageUrl = options.displayImageUrl;
    modalRef.componentInstance.message = options.message;
    modalRef.componentInstance.isYesNo = options.yesAndNoButtons;
    modalRef.componentInstance.messageItems = options.messageItems;
    modalRef.componentInstance.messageFooter = options.messageFooter;
    return modalRef;
  }
  private displayConfirmDialogList(options: ConfirmOptionsList) {
    const modalRef = this.createConfirmDialogList(options);
    return from(modalRef.result).pipe(catchError(() => EMPTY));
  }

  noHpiData(vrm: string, quoteStateId: number, inputVrm: string, onYes: () => void, onNo: () => void) {
    const hpiMessage = inputVrm ? 'If this vehicle has not been used under another VRM, then either it’s an input error and you can re-enter VRM by tapping the button below.'
      : 'We can’t seem to match the vehicle with HPI. Has the vehicle used another VRM?';
    const modalRef = this.redirectToModal(HpiModalComponent);
    modalRef.componentInstance.isYesNo = true;
    modalRef.componentInstance.isOk = false;
    modalRef.componentInstance.isYes = true;
    modalRef.componentInstance.title = 'No HPI Data';
    modalRef.componentInstance.vrm = vrm;
    modalRef.componentInstance.isAltVrm = inputVrm;
    modalRef.componentInstance.message = hpiMessage;
    modalRef.result.then((isAltVrm) => {
      if (isAltVrm) {
        const modalRef2 = this.redirectToModal(WalkInComponent);
        modalRef2.componentInstance.isAltVRM = true;
        modalRef2.componentInstance.vrm = vrm;
        modalRef2.componentInstance.quoteStateId = quoteStateId;
        modalRef2.componentInstance.placeholder = 'Enter Alt VRM';
        modalRef2.componentInstance.isAltVrm = isAltVrm;
      }
    }, async () => {
      const modalRef3 = this.redirectToModal(HpiModalComponent);
      const message = 'If this vehicle has not recently used another VRM, please confirm the VRM is correct and registered to the vehicle before you proceed.';
      modalRef3.componentInstance.isYesNo = false;
      modalRef3.componentInstance.title = 'No HPI Data';
      modalRef3.componentInstance.vrm = vrm;
      modalRef3.componentInstance.message = message;
      await modalRef3.result.then(() => {
        onYes();
      }, () => {
        onNo();
      });
    });
  }

  redirectToModal(component): NgbModalRef {
    return this.modalService.open(component, {
      keyboard: false,
      backdrop: 'static',
      centered: true,
      size: 'm'
    });
  }

  async onManualLookup(quoteStateId, vrm, isStarPurchase?, allowManualLookup?, isQuickQuote?) {
    const modalRef = this.redirectToModal(ManualLookupComponent);
    modalRef.componentInstance.quoteStateId = quoteStateId;
    modalRef.componentInstance.vrm = vrm;
    modalRef.componentInstance.allowManualLookup = allowManualLookup;
    modalRef.componentInstance.isStartPurchase = isStarPurchase;
    modalRef.componentInstance.isQuickQuote = isQuickQuote;
    let confirm;
    await modalRef.result.then(
      () => { confirm = true; },
      () => { confirm = false; }
    );

    return confirm;
  }

  appointmentNotFound() {
    const displayImageUrl = ConfirmDialogImageTypes.cancelledPurchaseUrl;
    const message = 'An active appointment for that VRM could not be found';
    const options = createConfirmOptions(message, displayImageUrl, false);
    return this.displayConfirmDialog(options);
  }

  upjarSaved() {
    const displayImageUrl = ConfirmDialogImageTypes.thumbsUpUrl;
    const message = 'That Upjar has been sent to the Branch Manager.';
    const options = createConfirmOptions(message, displayImageUrl, false);
    return this.displayConfirmDialog(options);
  }

  purchaseError(message: string) {
    const options = createConfirmOptions(message, ConfirmDialogImageTypes.warningUrl, false);
    return this.displayConfirmDialog(options);
  }

  async onChangeVrm(vrm) {
    const modalRef = this.redirectToModal(HpiModalComponent);
    modalRef.componentInstance.displayImageUrl = ConfirmDialogImageTypes.warningUrl;
    modalRef.componentInstance.isOk = true;
    modalRef.componentInstance.vrm = vrm;
    modalRef.componentInstance.title = 'Change VRM';
    modalRef.componentInstance.message = 'The vehicle details are different, you can continue, but you will need to request an auth by clicking the next button';
    let confirm;
    await modalRef.result.then(
      () => {},
      () => { confirm = true; }
    );
    return confirm;
  }
}
