import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import moment from 'moment';
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError, concatMap, take, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { AnswerValue } from '../purchase/models/answer-value.model';
import { AuthorizationRequired } from '../user-input/models/authorization-required.model';
import { PurchaseJourney } from '../user-input/models/purchase-journey.model';
import { QuestionFlowData } from '../user-input/models/question-flow-data.model';
import { Question } from '../user-input/models/question.model';
import { StateAnswers } from '../user-input/models/state-answers.model';
import { StateResult } from '../user-input/models/state-result.model';
import { StateTransition } from '../user-input/models/state-transition.model';
import { State } from '../user-input/models/state.model';
import { ImageService } from './image.service';
import { NotificationService } from './notification.service';
import { AuthorizationRequestService } from './authorization-request.service';
import { AuthorizationRequest } from '../authorization/models/authorization-request.model';
import { Router } from '@angular/router';
import { ScreenControlTypes } from '../enums/screen-control-types';
import { KeyValuePair } from '../models/key-value-pair.model';
import { AuthorizationTypes } from '../authorization/enums/authorization-types';
import { CompletedState } from '../user-input/models/completed-state.model';
import { ValuationSummary } from '../damage/models/valuation-summary.model';
import { CustomErrorHandler } from '../custom-error-handler';
import { BuyerService } from './buyer.service';

@Injectable({
  providedIn: 'root',
})
export class PathFinderService {
  constructor(
    private http: HttpClient,
    private notifications: NotificationService,
    private imageService: ImageService,
    private authorizationRequestService: AuthorizationRequestService,
    private router: Router,
    private customErrorHandler: CustomErrorHandler, 
    private buyerService: BuyerService) { }

  private quoteStateId: number;
  private valuationAmount: number;
  private completedStates: Array<CompletedState>;
  private category: string;
  private savedAnswers: Array<AnswerValue>;
  private authorizationRequests: Array<AuthorizationRequest>;
  private settings: Array<KeyValuePair>;
  private appraisalVehicleType: string;
  private purchaseJourney: PurchaseJourney;
  hasAuthorization: boolean;
  private transitionEvent: (onComplete: (performTransition: boolean) => void) => void;
  private stepbackInterceptor: () => boolean;

  getValuationAmount(): number {
    return this.valuationAmount;
  }

  setValuationAmount(valuation: ValuationSummary) {
    this.valuationAmount = valuation.vehiclePriceOffered;
    this.category = valuation.category;
    this.determineAppraisalVehicleType(valuation);
  }

  private determineAppraisalVehicleType(valuation: ValuationSummary) {
    const priceExcludingDamage = valuation.vehiclePriceOffered + valuation.totalDamageCost;
    const gradedAppraisalAmount = this.getPurchaseSettingAsNumber('GradedAppraisalAmount');
    if (this.isTradePurchase()) {
      this.appraisalVehicleType = 'Trade';
    } else if (priceExcludingDamage < gradedAppraisalAmount || this.isRegionInGradedTrial(priceExcludingDamage)) {
      this.appraisalVehicleType = 'Banger';
    } else {
      this.appraisalVehicleType = 'Hatchback';
    }
  }

  private isRegionInGradedTrial(price: number) {
    const trialAmount = this.getPurchaseSettingAsNumber('GradedAppraisalTrialAmount');
    if (price < trialAmount) {
      const region = this.buyerService.getBuyerFromStorage().regionId.toString();
      let isRegionInTrial = false;
      this.getPurchaseSetting('GradedAppraisalTrialRegions').toString().split(',').forEach(id => {
        if (region === id) {
          isRegionInTrial = true;
        }
      });
      return isRegionInTrial;
    }
    return false;
  }

  isTradePurchase(): boolean {
    return this.getAnswerValueForStringComparison('IsTrade', null) === 'true';
  }

  getAppraisalVehicleType(): string {
    return this.appraisalVehicleType;
  }

  updatePurchaseJourneyInLocalStorage(callback: (journey: PurchaseJourney) => void) {
    this.getPurchaseFlow$().pipe(
      tap(result => {
        this.purchaseJourney = result;
        localStorage.setItem('PurchaseJourney', JSON.stringify(result));
        if (callback) {
          callback(result);
        }
      }),
      catchError(err => {
        this.notifications.dangerToast('Failed to get purchase journey', err);
        return EMPTY;
      }),
      take(1)
    ).subscribe();
  }

  private getPurchaseFlow$(): Observable<PurchaseJourney> {
    const url = `${environment.ppxPrivateApi}api/State`;
    return this.http
      .get<PurchaseJourney>(url)
      .pipe(catchError((err) => throwError(err)));
  }

  private getPurchaseFlowFromLocalStorage(): PurchaseJourney {
    const storedObject = localStorage.getItem('PurchaseJourney');
    return JSON.parse(storedObject);
  }

  findState(stateName: string): State {
    const journey = this.getPurchaseFlowFromLocalStorage();
    const state = journey.states.find((element) => {
      return element.name === stateName;
    });
    return state;
  }

  getPreviousState(): CompletedState {
    if (this.completedStates?.length > 0) {
      return this.completedStates[this.completedStates.length - 1];
    }
    return null;
  }

  getPurchaseSection(stateName: string): number {
    return this.findState(stateName).purchaseSection;
  }

  getPurchaseDetail$(quoteStateId: number): Observable<QuestionFlowData> {
    const url = `${environment.ppxPrivateApi}api/State/${quoteStateId}`;
    return this.http
      .get<QuestionFlowData>(url)
      .pipe(catchError((err) => throwError(err)));
  }

  getAnswers(): Array<AnswerValue> {
    return this.savedAnswers;
  }

  getPurchaseSetting(key: string) {
    const setting = this.settings.find(x => x.key === key);
    return setting.value;
  }

  getPurchaseSettingAsNumber(key: string): number {
    return parseInt(this.getPurchaseSetting(key), 10);
  }

  appendAnswer(parameterName: string, value: string) {
    const previousAnswer = this.savedAnswers.find((element) => element.parameterName === parameterName);
    if (previousAnswer) {
      previousAnswer.value = value;
    } else {
      this.savedAnswers.push(new AnswerValue(parameterName, value, null, null, false, false));
    }
  }

  private checkIfPurchaseChanged(quoteStateId: number) {
    if (quoteStateId !== this.quoteStateId) {
      this.completedStates = new Array<CompletedState>();
      this.savedAnswers = new Array<AnswerValue>();
      this.valuationAmount = 0;
    }
    this.quoteStateId = quoteStateId;
  }

  private submitAnswers$(
    quoteStateId: number,
    answers: StateAnswers,
    requiredImage: number,
    isPostPurchase: boolean
  ): Observable<StateResult> {
    this.checkIfPurchaseChanged(quoteStateId);
    if (answers.nextStateName) {
      const nextState = this.findState(answers.nextStateName);
      const currentState = this.findState(answers.stateName);
      const canGoBack = currentState ? nextState.purchaseSection === currentState.purchaseSection : true;
      if (!canGoBack) {
        this.completedStates.forEach(item => item.canGoBack = false);
      }
      this.completedStates.push({ stateName: answers.stateName, canGoBack: canGoBack, purchaseSection: currentState.purchaseSection });
    }

    let hasImages = false;
    answers.answers.forEach((element) => {
      if (element.images && element.images.length > 0) {
        hasImages = true;
      }
    });
    const url = `${environment.ppxPrivateApi}api/State/${quoteStateId}/${isPostPurchase}`;
    const isLastState = answers.stateName === 'Vehicle Images' || isPostPurchase;
    if (isLastState) {
      this.hasAuthorization = false;
    }
    if ((hasImages || requiredImage > 0) && (isPostPurchase || !this.hasAuthorization)) {
      return this.imageService
        .submitImages$(this.quoteStateId, requiredImage, isPostPurchase)
        .pipe(tap(() => {
          this.hasAuthorization = false;
        }),
          concatMap(() => this.http.post<StateResult>(url, answers)),
          catchError((err) => {
            const error = {
              message: err.error,
            };
            this.customErrorHandler.handleError(error, false);
            return EMPTY
          })
        );
    } else {
      return this.http
        .post<StateResult>(url, answers)
        .pipe(catchError((err) => {
          const error = {
            message: err.error,
          };
          this.customErrorHandler.handleError(error, false);
          return EMPTY
        }));
    }
  }

  getStartingState(): State {
    const journey = this.getPurchaseJourney();
    return this.findState(journey.startingState);
  }

  getCompletedStates(quoteStateId: number) {
    this.checkIfPurchaseChanged(quoteStateId);
    return this.completedStates;
  }

  canGoBackCompletedStates(statesArr: Array<string>, currentState: string) {
    const lastState = this.findState(currentState);
    const states = [];
    statesArr.forEach(item => {
      const state = this.findState(item);
      states.push(new CompletedState(item, state.purchaseSection === lastState.purchaseSection, state.purchaseSection));
    });
    return states;
  }

  goBackToPreviousState(quoteStateId: number, onRolledBack: (newState: State) => void) {
    this.checkIfPurchaseChanged(quoteStateId);
    const previousState = this.getPreviousState();
    let canGoBack = true;
    if (this.stepbackInterceptor) {
      canGoBack = this.stepbackInterceptor();
    }
    if (previousState.stateName !== '' && canGoBack) {
      this.rollback$(this.quoteStateId, previousState.stateName, previousState.purchaseSection).pipe(
        tap(() => {
          onRolledBack(this.findState(previousState.stateName));
        }),
        catchError(err => {
          this.notifications.dangerToast(`Failed to go back to ${previousState}`, err);
          onRolledBack(this.reset());
          return EMPTY;
        }),
        take(1)
      ).subscribe();
    } else {
      onRolledBack(null);
    }
  }

  rollback$(quoteStateId: number, stateName: string, purchaseSection: number) {
    const url = `${environment.ppxPrivateApi}api/State/rollback/${quoteStateId}`;
    const body = {
      stateName: stateName,
      purchaseSection: purchaseSection
    };
    return this.http.post<Array<AnswerValue>>(url, body).pipe(
      tap(result => {
        this.removeCompletedStates(stateName);
        this.savedAnswers = result;
      }),
      catchError((err) => throwError(err))
    );
  }

  private removeCompletedStates(stateName: string) {
    this.transitionEvent = null;
    this.stepbackInterceptor = null;
    if (stateName) {
      const index = this.completedStates.findIndex(element => {
        return element.stateName === stateName;
      });
      this.completedStates.splice(index, this.completedStates.length - index);
    } else {
      this.completedStates.splice(0, this.completedStates.length);
    }
  }

  setCompletedStates(quoteStateId: number, completedStates: Array<string>) {
    if (
      this.quoteStateId !== quoteStateId ||
      this.completedStates.length === 0
    ) {
      this.completedStates = [];
      completedStates.forEach(state => {
        this.completedStates.push({ stateName: state, canGoBack: false,
          purchaseSection: this.findState(state).purchaseSection });
      });
    }
    this.quoteStateId = quoteStateId;
  }

  setCompletedAnswers(answers: Array<AnswerValue>) {
    this.savedAnswers = answers;
  }

  reset(): State {
    this.removeCompletedStates(null);
    return this.getStartingState();
  }

  start(
    quoteStateId: number,
    questionFlowData: QuestionFlowData,
    isPostPurchase: boolean,
    onStart: (state: State) => void
  ) {
    this.checkIfPurchaseChanged(quoteStateId);
    this.transitionEvent = null;
    this.stepbackInterceptor = null;
    this.completedStates = this.canGoBackCompletedStates(questionFlowData.completedStates, questionFlowData.currentState);
    this.savedAnswers = questionFlowData.answers;
    this.authorizationRequests = questionFlowData.authorizationRequests;
    this.settings = questionFlowData.purchaseSettings;
    this.setValuationAmount(questionFlowData.valuation);
    const journey = this.getPurchaseJourney();
    if (journey.purchaseJourneyId === questionFlowData.purchaseJourneySetId) {
      this.retrieveState(journey, questionFlowData.currentState, onStart);
    } else {
      this.updatePurchaseJourneyInLocalStorage((result) => {
        this.retrieveState(result, questionFlowData.currentState, onStart);
      });
    }
  }

  public getAnsweredImageQuestions() {
    if (this.completedStates.length > 0) {
      const purchaseFlow = this.getPurchaseFlowFromLocalStorage();

      const filteredStates = purchaseFlow.states.filter((r) =>
        this.completedStates.find(x => x.stateName === r.name)
      );

      const results = filteredStates
        .filter((eachVal) => {
          if (eachVal.requiredImages !== 2) {
            const opt = eachVal.pages.some(({ questions }) =>
            questions.some(x => x.images)
          );
          return opt;
          }
        })
        .map((x) => x.pages.map((item) => item.questions).flat())
        .flat()
        .filter(
          (x) =>
          (x.images && !x.addedFromAnswers && this.findAnswerValue(x.parameterName, null, this.savedAnswers, false, false) &&
          this.findAnswerValue(x.parameterName, null, this.savedAnswers, false, false) !== 'false') ||
          (x.images && x.addedFromAnswers && this.findAnswerValue(x.parameterName, null, this.savedAnswers, false, false) === x.answerNeedsImage.toString())
        );
      return results;
    }
  }

  private FindQuestion(parameterName: string): Question {
    const purchaseFlow = this.getPurchaseFlowFromLocalStorage();
    for (let i = 0; i < purchaseFlow.states.length; i++) {
      for (let j = 0; j < purchaseFlow.states[i].pages.length; j++) {
        const question = purchaseFlow.states[i].pages[j].questions.find(q => q.parameterName === parameterName);
        if (question) {
          return question;
        }
      }
    }
    return null;
  }

  public GetInsuranceWriteOffDescription(...writeOffIds: Array<string>): Array<string> {
    const question = this.FindQuestion('InsuranceWriteOffDescription');
    const results = new Array<string>(writeOffIds.length);
    for (let i = 0; i < writeOffIds.length; i++) {
      const option = question.options.find(opt => opt.key === writeOffIds[i]);
      if (option) {
        results[i] = option.value;
      } else {
        results[i] = '';
      }
    }
    return results;
  }

  private canControlTypeTriggerImage(controlType: ScreenControlTypes): boolean {
    return controlType === ScreenControlTypes.IMAGE_UPLOAD_QUESTION || controlType === ScreenControlTypes.OUTSTANDING_FINANCE;
  }

  private retrieveState(
    journey: PurchaseJourney,
    currentState: string,
    onStateRetrieved: (state: State) => void
  ) {
    if (currentState) {
      const state = this.findState(currentState);
      onStateRetrieved(state);
    } else {
      const state = this.findState(journey.startingState);
      onStateRetrieved(state);
    }
  }

  async transition(
    quoteStateId: number,
    currentState: State,
    currentAnswers: Array<Question>,
    onAnswerSave: (result: StateResult) => void,
    onTransition: (state: State, authorizationRequest: AuthorizationRequest[]) => void,
    onCancel: () => void,
    isPostPurchase: boolean = false
  ): Promise<void> {

    if (this.transitionEvent) {
      this.transitionEvent(async (performTransition: boolean) => {
        if (performTransition) {
          await this.checkAuthorization(quoteStateId, currentState, currentAnswers,
            onAnswerSave, onTransition, onCancel, isPostPurchase);
        } else {
          onTransition(this.findState(currentState.name), null);
        }
      });
    } else {
      await this.checkAuthorization(quoteStateId, currentState, currentAnswers,
        onAnswerSave, onTransition, onCancel, isPostPurchase);
    }
  }

  private async canContinuePurchase(
    currentState: State,
    currentAnswers: Array<Question>,
    onRollbackTransition: (state: State, authorizationRequest: AuthorizationRequest[]) => void,
  ): Promise<boolean> {
    // Get a list of answers that need submitting
    const answersToSave = new Array<Question>();
    if (currentAnswers) {
      currentAnswers.forEach((question) => {
        answersToSave.push(question);
        this.setAnswer(question);
      });
    }

    let nextState = null;
    const matchedTransition = this.findMatchingTransition(currentState, answersToSave);
    if (matchedTransition) {
      nextState = this.findState(matchedTransition.nextState);
      if (currentState.purchaseSection !== nextState.purchaseSection) {
        const serverState = await this.getCurrentState$(this.quoteStateId).toPromise();
        if (serverState.stateName !== currentState.name) {
          // Incomplete state, need to rollback
          await this.rollback$(this.quoteStateId, serverState.stateName, currentState.purchaseSection).toPromise();
          onRollbackTransition(this.findState(serverState.stateName), null);
          return false;
        }
      }
    }
    return true;
  }

  private async checkAuthorization(
    quoteStateId: number,
    currentState: State,
    currentAnswers: Array<Question>,
    onAnswerSave: (result: StateResult) => void,
    onTransition: (state: State, authorizationRequest: AuthorizationRequest[]) => void,
    onCancel: () => void,
    isPostPurchase: boolean
  ): Promise<void> {

    let response;
    const canContinue = await this.canContinuePurchase(currentState, currentAnswers, onTransition);
    if (canContinue) {
      const authsRequired = this.authorizationRequired(currentState, currentAnswers);
      if (authsRequired && authsRequired.length > 0) {
        if (authsRequired.find(x => x.authorizationTypeId === AuthorizationTypes.V5QualityCheck)) {
          this.imageService
            .submitImages$(this.quoteStateId, currentState.requiredImages, isPostPurchase)
            .pipe(tap(result => {
              this.hasAuthorization = true;
              this.imageService.imagesToSave.forEach(imageToSave => {
                result.forEach(documentId => {
                  imageToSave.documentScanId = documentId;
                });
              });
            }),
              catchError((err) => throwError(err))
            ).subscribe();
        }

        response = await this.authorizationRequestService.processRequestsAsync(quoteStateId, authsRequired, this.authorizationRequests);
        if (!response || response.length === 0 || response.some(a => a.declined) || response.some(a => !a.approved && !a.declined)) {
          onCancel();
          return;
        }
      }

      this.performTransition(quoteStateId, currentState, currentAnswers, isPostPurchase, onTransition, onAnswerSave, response);
    }
  }

  private performTransition(
    quoteStateId: number,
    currentState: State,
    currentAnswers: Array<Question>,
    isPostPurchase: boolean,
    onTransition: (state: State, authorizationRequest: AuthorizationRequest[]) => void,
    onAnswerSave: (result: StateResult) => void,
    response: AuthorizationRequest[]
  ) {
    // Get a list of answers that need submitting
    const answersToSave = new Array<Question>();
    if (currentAnswers) {
      currentAnswers.forEach((question) => {
        answersToSave.push(question);
        this.setAnswer(question);
      });
    }

    let nextState = null;
    const matchedTransition = this.findMatchingTransition(currentState, answersToSave);
    if (matchedTransition) {
      nextState = this.findState(matchedTransition.nextState);
      if (currentState.purchaseSection !== nextState.purchaseSection) {
        this.completedStates.forEach(item => item.canGoBack = false);
      }
    }
    if (currentState && quoteStateId > 0) {
      this.submitAnswers(quoteStateId, currentState, nextState, answersToSave, isPostPurchase, false, onAnswerSave);
    }

    this.transitionEvent = null;
    this.stepbackInterceptor = null;
    onTransition(nextState, response);
  }

  getCurrentState$(quoteStateId: number): Observable<CompletedState> {
    const url = `${environment.ppxPrivateApi}api/State/current/${quoteStateId}`;
    return this.http.get<CompletedState>(url).pipe(catchError((err) => throwError(err)));
  }

  private setAnswer(question: Question) {
    // Add/update previous answers with the ones being submitted
    const previousAnswer = this.savedAnswers ? this.savedAnswers.find((element) => element.parameterName === question.parameterName) : null;
    if (previousAnswer) {
      previousAnswer.value = question.value;
      previousAnswer.displayValue = question.displayValue;
      previousAnswer.missingDocument = question.missingDocument;
      previousAnswer.isRolledBack = false;
    } else {
      if (question) {
        this.savedAnswers.push(new AnswerValue(question.parameterName, question.value, question.displayValue, question.missingDocument, false, false));
      }
    }
  }

  private findMatchingTransition(
    state: State,
    answersToSave: Array<Question>
  ): StateTransition {
    if (state?.transitions) {
      for (const transition of state.transitions) {
        if (transition.condition) {
          if (
            this.doesMatchTransition(transition, answersToSave)
          ) {
            return transition;
          }
        } else {
          return transition;
        }
      }
    }
    return null;
  }

  private addAnswerForSaving(currentAnswers: Array<Question>, parameterName: string, answerValue: string) {
    const question = new Question(parameterName, answerValue);
    // Add to current answers for saving to the database
    currentAnswers.push(question);
    // Add to existing answers for use in transitions
    this.setAnswer(question);
  }

  private doesMatchTransition(transition: StateTransition, currentAnswers: Array<Question>) {
    if (transition.condition.field === 'IsRKC') {
      const isRkc = this.isRecentKeeperChange(currentAnswers, this.savedAnswers, this.getValuationAmount());
      this.addAnswerForSaving(currentAnswers, 'IsRKC', isRkc.toString());
      return isRkc;
    } else if (transition.condition.field === 'NextOfKin' || transition.condition.field === 'EstateValue') {
      const triggerD2 = this.triggerD2form(currentAnswers);
      return triggerD2;
    } else if (transition.condition.field === 'CustomerOwnerNameMismatch') {
      const isMismatch = this.isOwnerNameMismatch(currentAnswers);
      this.addAnswerForSaving(currentAnswers, 'CustomerOwnerNameMismatch', isMismatch.toString());
      return isMismatch;
    } else if (transition.condition.field === 'IsAppraisalVideoRequired') {
      const videoReason = this.isAppraisalVideoRequired(currentAnswers);
      this.addAnswerForSaving(currentAnswers, 'VideoRequiredReason', videoReason);
      return videoReason !== null;
    } else if (transition.condition.field === 'RequiresChassisNumberPhoto') {
      const requiresPhoto = this.isChassisNumberPhotoRequired();
      this.addAnswerForSaving(currentAnswers, 'RequiresChassisNumberPhoto', requiresPhoto.toString());
      return requiresPhoto;
    } else {
      const answer = this.getAnswerValue(transition.condition.field, currentAnswers);
      return this.conditionMatches(transition.condition.value.toString(), transition.condition.operator, answer);
    }
  }

  getAnswerValueForStringComparison(parameterName: string, currentAnswers: Array<Question>): string {
    let value = this.getAnswerValue(parameterName, currentAnswers);
    if (value) {
      value = value.toLowerCase().trim();
    }
    return value;
  }

  findAnswerValue(parameterName: string,
    currentAnswers: Array<Question>,
    savedAnswers: Array<AnswerValue>,
    includeRollback: boolean,
    forDisplay: boolean
  ): string {
    let answer = null;
    let isInCurrentAnswers = false;
    if (currentAnswers) {
      currentAnswers.forEach((question) => {
        if (question.parameterName === parameterName) {
          if (forDisplay) {
            answer = question.displayValue;
          } else {
            answer = question.value;
          }
          isInCurrentAnswers = true;
        }
      });
    }
    if (!isInCurrentAnswers && savedAnswers) {
      const previousAnswer = savedAnswers.find(
        (pa) =>
          pa.parameterName === parameterName &&
          (includeRollback || !pa.isRolledBack)
      );
      if (previousAnswer) {
        if (forDisplay) {
          answer = previousAnswer.displayValue;
        } else {
          answer = previousAnswer.value;
        }
      }
    }
    return answer;
  }

  private getInformedAnswerValue(parameterName: string, currentAnswers: Array<Question>): string {
    // This relies on not needing informedAnswer from older (saved) answers
    let answer = null;
    if (currentAnswers) {
      currentAnswers.forEach(question => {
        if (question.parameterName === parameterName) {
          answer = question.informedAnswer;
        }
      });
    }
    return answer;
  }

  getAnswerValue(parameterName: string, currentAnswers: Array<Question>): string {
    return this.findAnswerValue(parameterName, currentAnswers, this.savedAnswers, true, false);
  }

  getQuestion(parameterName: string): Question {
    this.purchaseJourney.states.forEach(state => {
      state.pages.forEach(page => {
        const question = page.questions.find(q => q.parameterName === parameterName);
        if (question) {
          return question;
        }
      });
    });
    return null;
  }

  private conditionMatches(expected: string, operator: string, actual: string) {
    if (actual === null) {
      return false;
    }
    if (operator === 'equals') {
      return actual.toString() === expected.toString();
    } else if (operator === '>') {
      return parseInt(actual, 10) > parseInt(expected, 10);
    } else if (operator === '<') {
      return parseInt(actual, 10) < parseInt(expected, 10);
    }
    return false;
  }

  private getPurchaseJourney(): PurchaseJourney {
    if (!this.purchaseJourney) {
      this.purchaseJourney = this.getPurchaseFlowFromLocalStorage();
    }
    return this.purchaseJourney;
  }

  isRecentKeeperChange(currentAnswers: Array<Question>, savedAnswers: Array<AnswerValue>, valuationAmount: number): boolean {
    let isRkc = false;
    const keeperChangeDate = this.findAnswerValue(
      'Keeper Acquired Date',
      currentAnswers,
      savedAnswers,
      false,
      false
    );
    const age = moment().diff(moment(keeperChangeDate), 'days');


    const journey = this.getPurchaseJourney();
    journey.rkcThresholds.forEach((threshold) => {
      if (
        valuationAmount > threshold.priceLowerLimit &&
        valuationAmount <= threshold.priceUpperLimit &&
        age <= threshold.ageLimit
      ) {
        isRkc = true;
      }
    });
    return isRkc;
  }

  private triggerD2form(currentAnswers: Array<Question>): boolean {
    const probateConfirmation = this.getAnswerValueForStringComparison('ProbateConfirmation', currentAnswers);
    if (probateConfirmation) {
      if (probateConfirmation === '1') {
        currentAnswers.push(new Question('NextOfKin', '0'.toString()));
        currentAnswers.push(new Question('EstateValue', '0'.toString()));
      }
    }
    const nextOfKin = this.getAnswerValueForStringComparison('NextOfKin', currentAnswers);
    const estateValue = this.getAnswerValueForStringComparison('EstateValue', currentAnswers);
    if (nextOfKin || estateValue) {
      return nextOfKin === '1' || estateValue === '1';
    } else {
      return false;
    }
  }

  private isOwnerNameMismatch(currentAnswers: Array<Question>): boolean {
    const customerName = this.getAnswerValueForStringComparison('CustomerName', currentAnswers);
    const ownerName = this.getAnswerValueForStringComparison('OwnerName', currentAnswers);
    if (ownerName && customerName) {
      return customerName !== ownerName;
    } else {
      return false;
    }
  }

  private isChassisNumberPhotoRequired(): boolean {
    return this.getValuationAmount() > this.getPurchaseSettingAsNumber('ChassisNumberMaxOfferPrice');
  }

  private isAppraisalVideoRequired(currentAnswers: Array<Question>): string {
    const valAmount = this.getValuationAmount();
    const isVan = this.getAnswerValue('BookId', currentAnswers) === '2';
    if (isVan && valAmount > this.getPurchaseSettingAsNumber('VanVideoValuationAmount')) {
      return 'Van exceeding value';
    } else if (!isVan && valAmount > this.getPurchaseSettingAsNumber('CarVideoValuationAmount')) {
      return 'Car exceeding value';
    }

    const buyerOnNaughtyList = this.getAnswerValueForStringComparison('VideoEnabledForVP', currentAnswers) === 'true';
    const siteOnNaughtyList = this.getAnswerValueForStringComparison('VideoEnabledForSite', currentAnswers) === 'true';
    if (buyerOnNaughtyList && valAmount > this.getPurchaseSettingAsNumber('EmployeeVideoValuationAmount')) {
      return 'Value above VP limit';
    } else if (siteOnNaughtyList && valAmount > this.getPurchaseSettingAsNumber('SiteVideoValuationAmount')) {
      return 'Value above site limit';
    }

    const capOnDodgyList = this.getAnswerValueForStringComparison('VideoRequiredForCap', currentAnswers) === 'true';
    if (capOnDodgyList) {
      if (this.isValuationAmountAboveCategoryThreshold(valAmount)) {
        return `Required for Cap - ${this.category}`;
      } else if (this.isMileageAboveCapVideoThreshold(currentAnswers)) {
        return 'Required for Cap - Mileage';
      }
    }
    return null;
  }

  isValuationAmountAboveCategoryThreshold(valuationAmount: number): boolean {
    // Here we are checking only vehicles that are on the dodgy CAP list
    if (this.category === 'TRADE' && valuationAmount > this.getPurchaseSettingAsNumber('CapCheckTradeValuationAmount')) {
      return true;
    } else if (this.category === 'COMMERCIAL' && valuationAmount > this.getPurchaseSettingAsNumber('CapCheckCommercialValuationAmount')) {
      return true;
    } else if (this.category === 'WHOLESALE' && valuationAmount > this.getPurchaseSettingAsNumber('CapCheckWholesaleValuationAmount')) {
      return true;
    } else if (!this.category) {
      return valuationAmount > this.getPurchaseSettingAsNumber('CapCheckValuationAmount');
    }
    return false;
  }

  isMileageAboveCapVideoThreshold(currentAnswers: Array<Question>): boolean {
    const mileage = parseInt(this.getAnswerValue('Mileage', currentAnswers), 10);
    const threshold = this.getPurchaseSettingAsNumber('CapCheckVideoMileage');
    return mileage > threshold;
  }

  isMileageAboveCapAuthThreshold(currentAnswers: Array<Question>): boolean {
    const mileage = parseInt(this.getAnswerValue('Mileage', currentAnswers), 10);
    const threshold = this.getPurchaseSettingAsNumber('CapCheckAuthMileage');
    return mileage > threshold;
  }

  isValuationAmountAboveThreshold(valuationAmount: number): boolean {
    // Here we are checking just on valuation - it does not have to be on the dodgy CAP list
    const threshold = this.getPurchaseSettingAsNumber('EngineCheckValuationAmount');
    return valuationAmount > threshold;
  }

  submitAnswers(
    quoteStateId: number,
    state: State,
    nextState: State,
    currentAnswers: Array<Question>,
    isPostPurchase: boolean,
    isSuspendingPurchase: boolean,
    onSave: (result: StateResult) => void
  ) {
    const stateAnswers = new StateAnswers();
    stateAnswers.stateName = state.name;
    if (nextState) {
      stateAnswers.nextStateName = nextState.name;
    }
    stateAnswers.valuationRequired = state.valuationOnSubmit;
    stateAnswers.nextStateRequiredImages = state.nextStateRequiredImages;
    stateAnswers.answers = currentAnswers;
    stateAnswers.isSuspendingPurchase = isSuspendingPurchase;
    stateAnswers.vCheckRequired = state.valuationSalvagedOnSubmit;
    stateAnswers.purchaseSection = nextState ? nextState.purchaseSection : state.purchaseSection;

    this.submitAnswers$(
      quoteStateId,
      stateAnswers,
      state.requiredImages,
      isPostPurchase
    )
      .pipe(
        tap((result) => {
          if (result.valuation) {
            this.savedAnswers.push(new AnswerValue('IsConvertible', result.valuation.isConvertible, null, null, false, false));
          }
          if (onSave) {
            onSave(result);
          }
        }),
        catchError((err) => {
          if (err.status === 409) {
            this.notifications.dangerToast(
              err.error.message, null
            );
            this.router.navigate(['/']);
          } else {
            this.notifications.dangerToast(
              `Failed to save ${state.name}, please retry`, err
            );
            if (onSave) {
              const result = new StateResult();
              result.returnState = state;
              this.removeCompletedStates(state.name);
              onSave(result);
            }
          }
          return EMPTY;
        }),
        take(1)
      )
      .subscribe();
  }

  registerTransitionEvent(onTransition: (onComplete: (performTransition: boolean) => void) => void) {
    this.transitionEvent = onTransition;
  }

  registerStepbackInterceptor(onBack: () => boolean) {
    this.stepbackInterceptor = onBack;
  }

  authorizationRequired(currentState: State, currentAnswers: Array<Question>): Array<AuthorizationRequired> {
    const matchingAuths = new Array<AuthorizationRequired>();
    if (!currentState.authRequired || currentState.authRequired.length === 0) {
      return matchingAuths;
    }

    for (const auth of currentState.authRequired) {
      if (auth.valueParameter) {
        if (auth.valueParameter.includes(',')) {
          const questions = auth.valueParameter.split(',');
          auth.value = '';
          questions.forEach((element, idx, array) => {
            const value = this.getAnswerValue(element, currentAnswers)?.toString();
            if (idx === array.length - 1) { 
              auth.value = auth.value + value
            } else {
              auth.value = auth.value + value + ','
            }
          });
        } else {
          auth.value = this.getAnswerValue(auth.valueParameter, currentAnswers)?.toString();
        }
      }

      if (auth.conditions) {
        let matches = true;
        auth.conditions.forEach(condition => {
          const answer = this.getAnswerValue(condition.field, currentAnswers);
          let expected = condition.value;
          if (expected === 'informedAnswer') {
            expected = this.getInformedAnswerValue(condition.field, currentAnswers);
          }
          if (!this.conditionMatches(expected, condition.operator, answer)) {
            matches = false;
          }
        });
        if (matches) {
          matchingAuths.push(auth);
        }
      } else {
        matchingAuths.push(auth);
      }
    }

    return matchingAuths;
  }

  checkForRiskyCapCheckFailure(): boolean {
    return this.completedStates.length === 0 && this.getAnswerValueForStringComparison('RiskyCapFailure', null) === 'true';
  }

  endTransitionEvent() {
    this.transitionEvent = null;
  }

  getAuthorizationRequests() {
    return this.authorizationRequests;
  }

}
