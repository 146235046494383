import { EventEmitter, Injectable } from '@angular/core';
import { VehicleListValue } from '../walk-in/models/vehicle-list-value.model';
@Injectable({
  providedIn: 'root',
})
export class SharedService {
  public source = new EventEmitter<boolean>();
  public runner = new EventEmitter<boolean>();
  public vrm = new EventEmitter<string>();
  public isRelated = new EventEmitter<boolean>();
  public selectedVehicle = new EventEmitter<VehicleListValue>();
  public selectedPlate = new EventEmitter<string>();
  public isManualLookup = false;


  getItem(key) {
    if (localStorage.getItem(key)) {
      return localStorage.getItem(key);
    }
    return null;
  }

  setDateItem(key) {
    localStorage.setItem(key, new Date().toISOString());
  }

  setIsManualLookup(value: boolean) {
    this.isManualLookup = value;
  }

  getIsManualLookup(): boolean {
    return this.isManualLookup;
  }
}
