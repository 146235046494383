import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-hpi-modal',
  templateUrl: './hpi-modal.component.html',
  styleUrls: ['./hpi-modal.component.scss']
})
export class HpiModalComponent  {
  @Input() title: string;
  @Input() vrm: string;
  @Input() displayImageUrl: string;
  @Input() message: string;
  @Input() isYesNo = false;
  @Input() isOk = false;
  @Input() isYes = false;
  @Input() altVrmFlow: boolean;
  @Input() altVrm: string;
  @Input() quoteStateId: number;


  constructor(public activeModal: NgbActiveModal) { }

  closeModal(): void {
    this.activeModal.close(true);
  }

  dismissModal(): void {
    this.activeModal.dismiss();
  }

  plateSelected(vrm) {
    const isAppointmentVrm = vrm === this.vrm;
    const data = {
      isAppointmentVrm : isAppointmentVrm,
      appointmentVrm: this.vrm,
      altVrm: this.altVrm,
      quoteStateId: this.quoteStateId 
    }
    this.activeModal.close(data);
  }

  altCloseModal(): void {
    this.activeModal.close(false);
  }
}
