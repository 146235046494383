import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmDialogService } from '../../confirm-dialog/services/confirm-dialog.service';

@Component({
  selector: 'app-lookup-error',
  templateUrl: './lookup-error.component.html',
  styleUrls: ['./lookup-error.component.scss'],
})
export class LookupErrorComponent {
  @Input() vrm: string;
  @Input() allowManualLookup: boolean;
  @Input() missingVrmRelationship: boolean;
  @Input() isStartPurchase: boolean;
  @Input() isQuickQuote: boolean;
  @Input() bookedWithNoHpi: boolean;
  @Input() unableToPurchase: boolean;
  @Input() highValueRequiresAppointment: boolean;
  @Output() trySearchAgain = new EventEmitter<boolean>();
  @Output() bookAppointment = new EventEmitter<boolean>();

  missingAltVrm: boolean;

  constructor(private modalService: NgbModal, private confirmService: ConfirmDialogService) {
  }

  noAltVrm() {
    this.missingAltVrm = false;
    this.unableToPurchase = true;
    this.bookedWithNoHpi = false;
  }

  yesAltVrm() {
    this.trySearchAgain.emit(true);
  }

  vrmCorrect() {
    if (this.allowManualLookup) {
      this.closeModal();
      this.onManualLookup();
    } else {
      this.missingAltVrm = false;
      this.missingVrmRelationship = false;
      this.unableToPurchase = true;
    }
  }

  reenterVrm() {
    if (this.missingVrmRelationship) {
      this.trySearchAgain.emit(this.missingVrmRelationship);
    } else {
      this.trySearchAgain.emit(false);
    }
  }

  closeModal() {
    this.modalService.dismissAll();
  }

  get modalTitle() {
    if (this.unableToPurchase) {
      return 'Unable to Purchase ';
    } else if (this.highValueRequiresAppointment) {
      return 'Appointment Required';
    } else {
      return 'No HPI Data';
    }
  }

  async onManualLookup() {
    await this.confirmService.onManualLookup(0, this.vrm, this.isStartPurchase, true, this.isQuickQuote);
  }

  startBookingAppointment() {
    this.bookAppointment.emit(true);
  }
}
