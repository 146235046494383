<div *ngIf="(modalData$ | async) as appointment">
  <div class="modal-header ">
    <h4 class="modal-title text-center " *ngIf="!isTaskAction">{{authTask.authType}}</h4>
    <img alt="" class="modal-close" (click)="dismissModal()">
  </div>
  <div class="modal-body">
    <input type="text" style="display:none" ngbAutofocus />
    <div class="container">
      <div class="row">
        <div class="col-4 pad-bottom ps-0 pe-0 extendMobile">
          <div class=" pad-right rounded-edge obfuscated-playback">
            <div class="modal-data-label modal-data-top-left modal-data-top-right">
              Buyer's Name
            </div>
            <div class="modal-data py-2">
              {{ appointment.valuation.buyersName }}
            </div>
            <div class="modal-data-label">
              Branch
            </div>
            <div class="modal-data py-2 modal-data-bottom-left modal-data-bottom-right">
              {{ appointment.valuation.siteName }}
            </div>
            <div class="modal-data-label">
              Buyer Accuracy Level
            </div>
            <div class="modal-data py-2 modal-data-bottom-left modal-data-bottom-right">
              {{ buyerExpLevel }}
            </div>
            <div class="call-button">
              <a class="btn btn-block pe-4 text-center" href="tel:{{appointment.customerPhone}}">
                <img alt="" src="../../assets/icons/buttons/CloudPeople.png">
                <div class="bold">Contact Buyer</div>
              </a>
            </div>
          </div>
        </div>
        <div class="col-3 pad-bottom ps-0 extendMobile">
          <div class="white-fill row me-0 pad-left pad-right rounded-edge obfuscated-playback">
            <div class="col-12 modal-data-label modal-data-top-left modal-data-top-right">
              VRM
            </div>
            <div class="col-12 modal-data ">
              {{ appointment.vrm }}
            </div>
            <div class="col-6 modal-data-label modal-data-left-col">
              Original Offer
            </div>
            <div class="col-6 modal-data-label">
              Offer Price
            </div>
            <div class="col-6 modal-data modal-data-left-col">
              {{ appointment.valuation.originalVehiclePriceOffered | currency:'GBP'}}
            </div>
            <div class="col-6 modal-data ">
              {{ appointment.valuation.vehiclePriceOffered | currency:'GBP' }}
            </div>
            <div class="col-12 modal-data-label">
              Previous Owners
            </div>
            <div class="col-12 modal-data ">
              {{ appointment.valuation.previousOwners }}
            </div>
            <div class="col-6  modal-data-label modal-data-left-col">
              Private Plate
            </div>
            <div class="col-6 modal-data-label">
              Service History
            </div>
            <div class="col-6 modal-data private-plate modal-data-left-col">
              <div [ngClass]="{ 
                'show-private-plate-tick' : checkPrivatePlate(appointment.valuation.privatePlate)}">
              </div>
            </div>
            <div class="col-6 modal-data ">
              {{ appointment.valuation.serviceHistoryDescription }}
            </div>
          </div>
        </div>
        <div class="col-5 pad-bottom ps-0 pe-0 extendMobile">
          <div class="white-fill row me-0 pad-left rounded-edge obfuscated-playback">
            <div class="col-6 modal-data-label modal-data-left-col modal-data-top-left">
              CAP ID
            </div>
            <div class="col-6 modal-data-label modal-data-top-right">
              Recorded Mileage
            </div>
            <div class="col-6 modal-data modal-data-left-col single-text">
              {{ appointment.capId }}
            </div>
            <div class="col-6 modal-data single-text">
              {{ appointment.mileage | number }}
            </div>
            <div class="col-6 modal-data-label modal-data-left-col">
              Make
            </div>
            <div class="col-6 modal-data-label">
              Model
            </div>
            <div class="col-6 modal-data modal-data-left-col double-text">
              {{ appointment.make }}
            </div>
            <div class="col-6 modal-data double-text">
              {{ appointment.model }} - {{ appointment.derivative }}
            </div>
            <div class="col-6 modal-data-label modal-data-left-col">
              Engine
            </div>
            <div class="col-6 modal-data-label">
              VIN
            </div>
            <div class="col-6 modal-data modal-data-left-col single-text">
              {{ appointment.valuation.engineSize + "cc" }}
            </div>
            <div class="col-6 modal-data single-text">
              {{ appointment.vin }}
            </div>
            <div class="col-6 modal-data-label modal-data-left-col">
              Grade
            </div>
            <div class="col-6 modal-data-label">
              Vehicle Age
            </div>
            <div class="col-6 modal-data modal-data-left-col single-text modal-data-bottom-left">
              {{ appointment.valuation.grade }}
            </div>
            <div class="col-6 modal-data single-text modal-data-bottom-right">
              {{ appointment.vehicleAge }}
            </div>
          </div>
        </div>
        <div *ngIf="hasAuthDetailsTab" class="col-12 pad-top row ms-0 rounded-edge ps-0 pe-0">
          <div class="col-6 modal-data-label tab auth-tab-header" [ngClass]="activeTab === 0 ? 'active' : 'inactive'" (click)="setActiveTab(0)">
            Auth Details
          </div>
          <div class="col-6 modal-data-label tab auth-tab-header" [ngClass]="activeTab === 1 ? 'active' : 'inactive'" (click)="setActiveTab(1)">
            Answers
          </div>
          <div *ngIf="activeTab === 0" class="col-12 row ms-0 me-0 auth-details">

            <div class="col-12 row ms-0 me-0" *ngIf="isVehicleDetailsChanged">
              <div class="col-md-5 col-sm-12 pb-3 replace-padding">
                <div class="auth-blue">User Entered Details</div>
                <app-vehicle-image [vehicleImage]='vehicleDetails["imageUrl"]' [defaultVehicleImage]='defaultVehicleImage' [isStartPurchase]='true'>
                </app-vehicle-image>
                <app-vehicle-details [mileage]='mileage' [HPIFirstRegistered]="HPIFirstRegistered" [isUserEnteredDate]="true" [vehicleDetails]='vehicleDetails' [hideDetails]='false' [isWalkin]='true' [altStyle]="1"
                 >
                </app-vehicle-details>
              </div>
              <div class="col-md-2 col-sm-12 pb-3 centered">
                <img class="d-none d-md-block" src="../../assets/images/bigGreenArrow.svg">
                <img class="d-block d-md-none rotate" src="../../assets/images/bigGreenArrow.svg">
              </div>
              <div class="col-md-5 col-sm-12 pb-3 replace-padding">

                <div class="auth-purple">HPI Details</div>
                <app-vehicle-image [vehicleImage]='hpiVehicleDetails["imageUrl"]' [defaultVehicleImage]='defaultVehicleImage' [isStartPurchase]='true'>
                </app-vehicle-image>
                <app-vehicle-details [mileage]='mileage' [HPIFirstRegistered]="HPIFirstRegistered" [isHPIDate]="true" [vehicleDetails]='hpiVehicleDetails' [hideDetails]='false' [isWalkin]='true' [altStyle]="2"
                >
                </app-vehicle-details>
              </div>
            </div>

            <div class="col-12 row ms-0 me-0" *ngIf="isAltVrm">
              <div class="col-md-5 col-sm-12 pb-3 replace-padding">
                <div class="auth-blue pb-3">User Entered Details</div>

                <div class="row alt-vrm mb-3 extra-top-margin">
                  <div class="col-md-7 col-sm-12 add-height">
                    <app-vehicle-image [vehicleImage]='vehicleDetails["imageUrl"]' [defaultVehicleImage]='defaultVehicleImage' [isStartPurchase]='false'>
                    </app-vehicle-image>

                  </div>
                  <div class="col-md-5 col-sm-12 pt-1">
                    <app-registration-plate [isStartPurchase]='isStartPurchase' [vrm]='vrm'></app-registration-plate>
                  </div>
                </div>
                <app-vehicle-details [mileage]='mileage' [vehicleDetails]='vehicleDetails' [hideDetails]='false' [isWalkin]='true' [altStyle]="1">
                </app-vehicle-details>
              </div>
              <div class="col-md-2 col-sm-12 pb-3 arrow">
                <img class="d-none d-md-block" src="../../assets/images/bigGreenArrow.svg">
                <img class="d-block d-md-none rotate" src="../../assets/images/bigGreenArrow.svg">
              </div>
              <div class="col-md-5 col-sm-12 pb-3 replace-padding">
                <div class="auth-purple pb-3">HPI Details</div>
                <div class="row alt-vrm mb-3 extra-top-margin">
                  <div class="col-md-5 col-sm-12">
                    <app-registration-plate *ngIf="isAltVrm" [isStartPurchase]='isStartPurchase' [vrm]='altVrm'></app-registration-plate>
                  </div>
                  <div class="col-md-7 col-sm-12 add-height">
                    <app-vehicle-image [vehicleImage]='hpiVehicleDetails["imageUrl"]' [defaultVehicleImage]='defaultVehicleImage' [isStartPurchase]='false'>
                    </app-vehicle-image>
                  </div>
                </div>

                <app-vehicle-details [mileage]='mileage' [vehicleDetails]='hpiVehicleDetails' [hideDetails]='false' [isWalkin]='true' [altStyle]="2" >
                </app-vehicle-details>
              </div>
            </div>

            <div class="col-12 row ms-0 me-0" *ngIf="isHpiMismatch">
              <div class="col-md-6 col-sm-12 pb-3">
                <div class="auth-green">Manual Lookup</div>
                <app-vehicle-image [vehicleImage]='vehicleDetails["imageUrl"]' [defaultVehicleImage]='defaultVehicleImage' [isStartPurchase]='true'>
                </app-vehicle-image>
                <app-vehicle-details [mileage]='mileage' [vehicleDetails]='vehicleDetails' [hideDetails]='false' [isWalkin]='true' [altStyle]="3">
                </app-vehicle-details>
              </div>
              <div class="col-md-6 col-sm-12 pb-3">
                <div class="auth-purple">HPI Details</div>
                <app-vehicle-image [vehicleImage]='hpiVehicleDetails["imageUrl"]' [defaultVehicleImage]='defaultVehicleImage' [isStartPurchase]='true'>
                </app-vehicle-image>
                <app-vehicle-details [mileage]='mileage' [vehicleDetails]='hpiVehicleDetails' [hideDetails]='false' [isWalkin]='true' [altStyle]="2">
                </app-vehicle-details>
              </div>
            </div>

            <div class="col-12 row ms-0 me-0 flex-auth-box" *ngIf="isFlex">
              <div class="col-5 right">
                <div class="centered">
                  <div class="flex-text top">Offer Price before Flex</div>
                  <div class="green-price">{{ flexAuth.priceBeforeFlex | currency : 'GBP' }}</div>
                  <div class="flex-text">Total Damage</div>
                  <div class="blue-price">{{ flexAuth.damageCost | currency : 'GBP' }}</div>
                </div>
              </div>
              <div class="col-2 centered">
                <img src="../../assets/images/bigGreenArrow.svg">
              </div>
              <div class="col-5 left">
                <div class="centered">
                  <div class="flex-text top">Offer Price after Flex</div>
                  <div class="green-price">{{ flexAuth.priceAfterFlex | currency : 'GBP' }}</div>
                  <div class="flex-text">Flex Amount</div>
                  <div class="blue-price">{{ flexAuth.flexAmount | currency : 'GBP' }}</div>
                </div>
              </div>
            </div>

            <div class="col-12 row ms-0 me-0 write-off-box" *ngIf="isInsuranceWriteOff">
              <div class="col-12 pb-3 pt-5 condition-box">
                <app-condition-records [records]="appointment.conditionRecords"></app-condition-records>
              </div>
              <div class="col-12 row pb-4">
                <div class="col-md-5 col-sm-12 right">
                  <div class="writeoff-option hpi">
                    <img [src]="getOptionIcon(suggestedWriteOffDesc)" alt="Option">
                    <div [innerHTML]="getOptionDescription(suggestedWriteOffDesc)"></div>
                  </div>
                </div>
                <div class="col-md-2 col-sm-12 centered">
                  <img src="../../assets/images/bigGreenArrow.svg">
                </div>
                <div class="col-md-5 col-sm-12 left">
                  <div class="writeoff-option">
                    <img [src]="getOptionIcon(chosenWriteOffDesc)" alt="Option">
                    <div [innerHTML]="getOptionDescription(chosenWriteOffDesc)"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 row ml-0 mr-0" *ngIf="isChangeVrm">
              <div class="col-md-12 col-sm-12 pb-3 replace-padding">
                <div class="auth-purple">HPI Details</div>
                <app-vehicle-image [vehicleImage]='vehicleDetails["imageUrl"]' [defaultVehicleImage]='defaultVehicleImage' [isStartPurchase]='true'>
                </app-vehicle-image>
                <app-vehicle-details [mileage]='mileage' [vehicleDetails]='vehicleDetails' [hideDetails]='false' [isWalkin]='true' [altStyle]="2">
                </app-vehicle-details>
                <div class="align-plates">
                  <app-registration-plate [isStartPurchase]='isStartPurchase' [vrm]='vrm'></app-registration-plate>
                  <div *ngIf="isChangeVrm"><img src="../../assets/right-arrow.svg" class="m-5"></div>
                  <app-registration-plate *ngIf="isChangeVrm" [isStartPurchase]='isStartPurchase' [vrm]='altVrm'></app-registration-plate>
                </div>
              </div>
            </div>

            <div class="col-12 row ms-0 me-0 write-off-box" *ngIf="isOptionalExtra">
              <div class="col-md-5 col-sm-12 pb-3 replace-padding">
                <app-vehicle-image [vehicleImage]='hpiVehicleDetails["imageUrl"]' [defaultVehicleImage]='defaultVehicleImage' [isStartPurchase]='true'>
                </app-vehicle-image>
                <app-vehicle-details [mileage]='mileage' [vehicleDetails]='vehicleDetails' [hideDetails]='false' [isWalkin]='true' [altStyle]="2">
                </app-vehicle-details>
              </div>
              <div class="col-12"></div>
              <div class="col-md-4 col-sm-12 pb-3 replace-padding">
                <div>
                  <app-optional-extras-display [optionalExtras]='optionalExtras'>
                  </app-optional-extras-display>
                </div>
              </div>
            </div>

            <div class="col-12 auth-red">
              {{ authTypeWarningMessage }}
            </div>
            <div *ngIf="isFlex" class="col-12 admin-fee-message">
              Admin fee of £{{ appointment.valuation.adminFee }} applied and not including any outstanding finance
            </div>
          </div>
          <div *ngIf="activeTab === 1" class="col-12 answers-table">
            <div class="row scroll">
              <div *ngFor="let answer of allAnswers; let i = index" class="col-4 obfuscated-playback answer-for">
                <div class="answer-box" (click)="show(answer)">
                  <div class="param-name">
                    {{answer.parameterName}}
                  </div>
                  <div class="param-value">
                    {{answer.displayValue}}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="!hasAuthDetailsTab" class="col-12 pad-top row ms-0 text-center rounded-edge ps-0 pe-0">
          <div class="col-12 modal-data-label">
            Answers
          </div>
          <div class="col-12 answers-table">
            <div class="row scroll">
              <div *ngFor="let answer of allAnswers; let i = index" class="col-4 obfuscated-playback answer-for">
                <div class="answer-box" (click)="show(answer)">
                  <div class="param-name">
                    {{answer.parameterName}}
                  </div>
                  <div class="param-value">
                    {{answer.displayValue}}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 collapsible-panel">
          <button type="button" class="button collapsible-toggle" [class.active]="!vehicleConditionCollapsed"
            (click)="vehicleConditionCollapsed = !vehicleConditionCollapsed">Vehicle Condition Report</button>
          <div #collapse="ngbCollapse" [(ngbCollapse)]="vehicleConditionCollapsed">
            <div class="collapsible-panel-content condition-report">
              <div *ngIf="appointment.valuation.damage.length === 0">
                <div class="centered mt-3 mb-3 condition-report-header">No condition has been added</div>
              </div>
              <div *ngIf="appointment.valuation.damage.length > 0">
                <div class="row condition-report-header">
                  <div class="col-3">Zone</div>
                  <div class="col-3">Component</div>
                  <div class="col-3">Fault</div>
                  <div class="col-2">Cost</div>
                  <div class="col-1">Flex</div>
                </div>
                <div class="row">
                  <ng-container *ngFor="let fault of appointment.valuation.damage">
                    <div class="col-3">{{ fault.zoneName }}</div>
                    <div class="col-3">{{ fault.componentName }}</div>
                    <div class="col-3">{{ fault.faultName }}</div>
                    <div class="col-2">{{ fault.cost | currency : 'GBP' }}</div>
                    <div class="col-1">{{ fault.flexAmount | currency : 'GBP' }}</div>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 collapsible-panel">
          <button type="button" class="button collapsible-toggle" [class.active]="!vehicleDetailsCollapsed" (click)="vehicleDetailsCollapsed = !vehicleDetailsCollapsed">Vehicle
            Details</button>
          <div #collapse="ngbCollapse" [(ngbCollapse)]="vehicleDetailsCollapsed">
            <div class="collapsible-panel-content">
              <div *ngIf="appointment.vehicleCheckId">
                <app-vehicle-check [authTask]="true" [quoteStateId]="appointment.quoteStateId" [vrm]="appointment.vrm" [vehicleDetails]="vehicleDetails"
                  [mileage]="mileage"></app-vehicle-check>
              </div>
              <div *ngIf="!appointment.vehicleCheckId">
                Vehicle check has not been done for this purchase
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 collapsible-panel">
          <button type="button" class="button collapsible-toggle" [class.active]="!mileageDetailsCollapsed" (click)="mileageDetailsCollapsed = !mileageDetailsCollapsed">Mileage
            Details</button>
          <div #collapse="ngbCollapse" [(ngbCollapse)]="mileageDetailsCollapsed">
            <div class="collapsible-panel-content">
              <div *ngIf="appointment.vehicleCheckId">
                <app-mileage-display [quoteStateId]="appointment.quoteStateId" [vrm]="appointment.vrm" [mileageDecision]="mileageDecision" [mileage]="mileage"
                  [isFromAuthModal]="true"></app-mileage-display>
              </div>
              <div *ngIf="!appointment.vehicleCheckId">
                Vehicle check has not been done for this purchase
              </div>
            </div>
          </div>
        </div>

        <div class="col-6 ps-0">
          <div class="documents-collapsible collapsible-panel">
            <button type="button" class="button collapsible-toggle" [class.active]="!documentsCollapsed" (click)="documentsCollapsed = !documentsCollapsed">Mandatory
              Images</button>
            <div #collapse="ngbCollapse" [(ngbCollapse)]="documentsCollapsed">
              <div class="collapsible-panel-content">
                <div class="row align-items-end" *ngIf="appointment.documents && appointment.documents.length > 0">
                  <div class="col-lg-4 col-md-6 col-sm-12 mb-5 summaryImage" *ngFor="let doc of appointment.documents">
                    <div *ngIf="!isPdf(doc.filePath)" class="fit-images">
                      <img [src]="doc.imgAsBase64String ?? doc.url" [alt]="doc.stampCode" class="largeThumbnail" (click)="showPreview(doc, doc.stampName, preview)" />
                    </div>
                    <div *ngIf="isPdf(doc.filePath)">
                      <a [href]="doc.imgAsBase64String" [download]="doc.fileName">
                        <img class="mediumThumbnail" src="../../assets/images/pdf.svg">
                      </a>
                    </div>
                    <div class="mt-2"><strong>{{ doc.stampName }}</strong></div>
                  </div>
                </div>
                <div *ngIf="!appointment.documents || appointment.documents.length === 0">
                  No documents exist for this purchase
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-6 pe-0">
          <div class="vehicle-images-collapsible collapsible-panel">
            <button type="button" class="button collapsible-toggle" [class.active]="!vehicleImagesCollapsed" (click)="vehicleImagesCollapsed = !vehicleImagesCollapsed">Vehicle
              Images</button>
            <div #collapse="ngbCollapse" [(ngbCollapse)]="vehicleImagesCollapsed">
              <div class="collapsible-panel-content">
                <div *ngIf="!appointment.vehicleImages || appointment.vehicleImages.length === 0">
                  No images exist for this purchase
                </div>
                <button class="btn btn-success my-1 mb-4" *ngIf="appointment.isPurchased" (click)="editVehicleImages()">
                  Add Images
                  <span class="fa fa-camera"></span>
                </button>
                <div class="row align-items-end" *ngIf="appointment.vehicleImages && appointment.vehicleImages.length > 0">
                  <div class="col-lg-4 col-md-6 col-sm-12 mb-5 summaryImage" *ngFor="let image of appointment.vehicleImages">
                    <img [src]="image.imgAsBase64String" [alt]="image.description" class="largeThumbnail" (click)="showPreview(image, image.description, preview)" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-1"></div>
        <div class="col-5 ps-0 modal-button-left pad-top">
          <button class="btn btn-block btn-lg modal-button" [ngClass]="cannotCancel ? 'button_grey' : 'button_red'" (click)="declineAuthTask(authTask)" [disabled]="cannotCancel">
            Decline
          </button>
        </div>
        <div class="col-5 modal-button-right pe-0 pad-top">
          <button class="btn btn-block btn-lg modal-button button_green" [ngClass]="{'loading': isCompleting}" (click)="approveAuthTask(authTask)">
            Approve
          </button>
        </div>
        <div class="col-1"></div>
      </div>
    </div>
  </div>
</div>

<ng-template #preview let-modal>
  <div class="modal-header">
    <h4 class="modal-title text-center">{{ previewStamp }}</h4>
  </div>
  <div class="modal-body">
    <img [src]="previewString" width="100%" />
  </div>
  <div class="modal-footer">
    <button class="btn btn-primary" (click)="modal.close()" ngbAutofocus>Close</button>
  </div>
</ng-template>


<ng-container *ngIf="loading">
  <app-loading-wbac-animation></app-loading-wbac-animation>
</ng-container>