import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { EMPTY, Subject, Subscription } from 'rxjs';
import { catchError, take, takeUntil, tap } from 'rxjs/operators';
import { NotificationService } from '../../services/notification.service';
import { ValuationService } from '../../services/valuation.service';
import { WalkInLookupData } from '../models/walk-in-lookup-data.model';
import { BookAppointmentService } from '../../book-appointment/services/book-appointment.service';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ModalHelper } from '../../helpers/modal-helper';
import { CustomValidators } from '../../custom-validators/custom-validators';
import { PostcodeLookupService } from '../../postcode-lookup/services/postcode-lookup.service';
import { VehicleListValue } from '../models/vehicle-list-value.model';
import { SharedService } from '../../core/shared.service';

@Component({
  selector: 'app-valuation-questions',
  templateUrl: './valuation-questions.component.html',
  styleUrls: ['./valuation-questions.component.scss']
})

export class ValuationQuestionsComponent implements OnInit, OnDestroy {
  lookupData: WalkInLookupData;
  valuationQuestionsForm: UntypedFormGroup;
  selectedMarketing = -1;
  selectedVat = -1;
  bookAppointmentModalRef: NgbModalRef;
  postcodeLookupModalRef: NgbModalRef;
  isNewPostcodeLookup: boolean;
  loading: boolean;
  bookingAppointmentSubscription: Subscription;
  destroy$ = new Subject();

  @Input() vrm: string;
  @Input() altVrm: string;
  @Input() mileage: number;
  @Input() bookId: number;
  @Input() quoteId: number;
  @Input() quoteGuid: string;
  @Input() vehicle: VehicleListValue;
  @Input() isStartPurchase: boolean;
  @Input() isQuickQuote: boolean;
  @Input() savedAnswers: WalkInLookupData;
  @Input() isBookedVrm: boolean;
  @Input() userData: WalkInLookupData;
  @Output() dismissModal = new EventEmitter<boolean>();
  @Output() bookingAppointment = new EventEmitter<boolean>();
  @Output() requiresManualValuation = new EventEmitter<boolean>();
  @Output() highValueRequiresAppointment = new EventEmitter<WalkInLookupData>();

  constructor(
    private bookAppointmentService: BookAppointmentService,
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private notifications: NotificationService,
    private postcodeLookupService: PostcodeLookupService,
    private valuationService: ValuationService,
    private sharedService: SharedService) { }

  ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  ngOnInit(): void {
    let mileage = '';
    let name = '';
    let email = '';
    let phone = '';
    if (this.userData) {
      mileage = this.userData.mileage.toString();
      name = this.userData.customerName;
      email = this.userData.emailAddress;
      phone = this.userData.telephoneNumber;
    }

    this.valuationQuestionsForm = this.formBuilder.group({
      mileage: [mileage, { validators: Validators.required, asyncValidators: [CustomValidators.validateNumber(1, 999999)] }],
      customerName: [name, { validators: Validators.compose([Validators.required, Validators.maxLength(50)]),
        asyncValidators: [CustomValidators.validateRegex(CustomValidators.customerNameRegex)] }],
      postcode: ['', { validators: Validators.compose([Validators.required, Validators.maxLength(8)]),
        asyncValidators: [CustomValidators.validateRegex(CustomValidators.postcodeRegex)] }],
      emailAddress: [email, { validators: Validators.compose([Validators.required, Validators.maxLength(256)]),
        asyncValidators: [CustomValidators.validateRegex(CustomValidators.emailAddressRegex)] }],
      telephone: [phone, { validators: Validators.compose([Validators.required, Validators.maxLength(64)]),
        asyncValidators: [CustomValidators.validateRegex(CustomValidators.telephoneRegex)] }],
      marketingEmails: ['', Validators.required],
      isVatRegistered: ['', Validators.required]
    });

    if (this.isQuickQuote) {
      this.valuationQuestionsForm.patchValue({
        mileage: this.mileage
      });
      ModalHelper.setModalPositionForCustomerQuestions();
    }

    if (this.bookId !== 2) {
      this.valuationQuestionsForm.patchValue({
        isVatRegistered: false
      });
    }
  }

  conditionallyRequiredValidator(predicate) {
    return ((formControl: AbstractControl) => {
      if (predicate()) {
        return Validators.required(formControl);
      }
      return null;
    });
  }

  startPurchase() {
    this.loading = true;
    this.lookupData = new WalkInLookupData(this.quoteId, this.quoteGuid, this.vrm, this.valuationQuestionsForm.get('mileage').value,
      null, null, this.valuationQuestionsForm.get('marketingEmails').value, this.valuationQuestionsForm.get('isVatRegistered').value,
      this.valuationQuestionsForm.get('customerName').value, this.valuationQuestionsForm.get('postcode').value,
      this.valuationQuestionsForm.get('emailAddress').value, this.valuationQuestionsForm.get('telephone').value);
    this.lookupData.vehicleImageUrl = this.vehicle.ImageUrl;
    this.lookupData.colour = this.vehicle.Colour;
    this.lookupData.altVrm = this.altVrm;
    this.lookupData.isManualLookup = this.sharedService.getIsManualLookup();
    if (this.isStartPurchase) {
      this.startPurchaseProcess();
    } else {
      this.startBookingAppointment(this.lookupData.postcode);
    }
  }

  startPurchaseProcess() {
    this.valuationService.startWalkinValuation$(this.lookupData).pipe(
      tap(result => {
        if (result.requiresManualValuation) {
          this.requiresManualValuation.emit(true);
        } else if (result.highValueRequiresAppointment) {
          this.highValueRequiresAppointment.emit(this.lookupData);
        } else {
          this.dismissModal.emit(true);
          this.router.navigate(['/purchase', result.quoteStateId]);
        }
      }),
      catchError(err => {
        this.notifications.dangerToast('Failed to start purchase', err);
        return EMPTY;
      }), take(1)).subscribe();
  }

  startBookingAppointment(postcode) {
    this.loading = true;
    this.bookingAppointmentSubscription = this.bookAppointmentService.openBookAppointmentModal$(postcode, this.lookupData, this.bookId,false, false, this.altVrm, this.isBookedVrm)
      .pipe(tap(result => {
        this.loading = false;
        if (this.isNewPostcodeLookup) {
          this.postcodeLookupService.setNewPostcodeSearchCompleted();
          this.isNewPostcodeLookup = false;
        }
        this.bookingAppointment.emit(true);
        this.bookAppointmentModalRef = result;
        this.listenToBookAppointmentOutput();
      }),
        catchError(err => {
          this.loading = false;
          this.notifications.dangerToast('Failed to start booking appointment', err);
          return EMPTY;
        }),
        takeUntil(this.destroy$)
      ).subscribe();
  }

  listenToBookAppointmentOutput() {
    this.bookAppointmentModalRef.componentInstance.backToValuationQuestions
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.backToValuationQuestions();
      });

    this.bookAppointmentModalRef.componentInstance.requiresManualValuation
    .pipe(takeUntil(this.destroy$))
    .subscribe(() => {
      this.requiresManualValuation.emit(true);
    });

    this.bookAppointmentModalRef.componentInstance.newPostcode
      .pipe(takeUntil(this.destroy$))
      .subscribe(postcode => {
        this.bookingAppointmentSubscription.unsubscribe();
        this.isNewPostcodeLookup = true;
        this.startBookingAppointment(postcode);
      });
  }

  backToValuationQuestions() {
    this.bookingAppointment.emit(false);
    ModalHelper.setModalPositionForCustomerQuestions();
  }

  setMarketing(marketing: boolean) {
    if (marketing) {
      this.selectedMarketing = 1;
    } else {
      this.selectedMarketing = 0;
    }
    this.valuationQuestionsForm.patchValue({
      marketingEmails: marketing
    });
  }

  setVat(vat: boolean) {
    if (vat) {
      this.selectedVat = 1;
    } else {
      this.selectedVat = 0;
    }
    this.valuationQuestionsForm.patchValue({
      isVatRegistered: vat
    });
  }

  scrollModalToTopPosition(topPositionRem: number) {
    ModalHelper.scrollModalToTopPosition(topPositionRem);
  }

  // prevents input from adding characters on tablets after max limit is reached
  // (very specific scenario, might be caused by a bug)
  preventInputOnTablet(inputMileage: string) {
    const inputLength = inputMileage.length;
    const isMaxInput = inputLength >= 8;
    if (isMaxInput) {
      inputMileage = inputMileage.slice(0, -1);
      this.valuationQuestionsForm.patchValue({
        mileage: inputMileage
      });
    }

  }
}
