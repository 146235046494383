import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable, throwError } from 'rxjs';
import { UserInputRequest } from '../user-input/models/user-input-request.model';
import { catchError } from 'rxjs/operators';
import { VehicleIdentification } from '../walk-in/models/vehicle-identification.model';

@Injectable({
  providedIn: 'root'
})
export class ToDoService {

  constructor(private http: HttpClient) { }

  getPostPurchaseQuestions$(quoteStateId, imageType): Observable<UserInputRequest> {
    const questionUrl = `${environment.ppxPrivateApi}api/ToDo/postPurchase/${quoteStateId}/${imageType}`;
    return this.http.get<UserInputRequest>(questionUrl).pipe(
      catchError(err => throwError(err))
    );
  }

  // null will be converted to string "null", it's best to pass an empty string
  saveAnswerManually$(quoteStateId, parameterName, parameterValue, isOptionalExtra, parameterDisplayValue = '') {
    const body = new HttpParams()
      .set('ParameterName', parameterName)
      .set('Value', parameterValue)
      .set('DisplayValue', parameterDisplayValue)
      .set('isOptionalExtra', isOptionalExtra);

    return this.http.post(`${environment.ppxPrivateApi}api/ToDo/saveAnswerManually/${quoteStateId}`, body.toString(),
      {
        headers: new HttpHeaders()
          .set('Content-Type', 'application/x-www-form-urlencoded')
      }).pipe(
        catchError(err => throwError(err))
      );
  }

  notPurchase$(quoteStateId, reasonId, desiredFinalPrice, comments): Observable<void> {
    const body = {
      reasonId: reasonId,
      desiredFinalPrice: desiredFinalPrice,
      comments: comments
    };
    return this.http.post<void>(`${environment.ppxPrivateApi}api/ToDo/notPurchased/${quoteStateId}`, body).pipe(
      catchError(err => throwError(err))
    );
  }

  selectVehicle$(vrm: string, capCode: string, colour: string, regYear: string, quoteStateId: number, fuelType: string): Observable<VehicleIdentification> {
    const url = `${environment.ppxPrivateApi}api/ToDo/vehicleSelection/${vrm}?capCode=${capCode}&colour=${colour}&regYear=${regYear}&quoteStateId=${quoteStateId}&fuelType=${fuelType}`;
    return this.http.get<VehicleIdentification>(url).pipe(
      catchError(err => throwError(err))
    );
  }
}
